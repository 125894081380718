import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
  ) {
        translate.setDefaultLang('en');
     } ;
  token = this.auth.getToken();
  session = sessionStorage;

  useLanguage(language: string) {
        this.translate.use(language);
  }

  logout(){
    this.auth.logOut();
    this.token = this.auth.getToken();
  }

  ngOnInit() {
    if(!this.token){
      this.router.navigate(['/login']);
    }
  }


}
