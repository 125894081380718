import { Injectable } from '@angular/core';
import { InitValues } from './initValues.service';

@Injectable({
  providedIn: 'root'
})
export class Filterbox {
  private airportList = this.initValuesService.getAirports();
  private groups = this.initValuesService.getGroups();
  private programs = this.initValuesService.getPrograms();

  constructor( private initValuesService : InitValues ) {

  }

  getAirports() {
    return this.airportList;
  }

  getGroups(){
    return this.groups;
  }

  getPrograms(){
    return this.programs;
  }

}
