import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Config {

  public ROOT_API : string = "https://fdm-back-prd-pp.storkjet.com/";
  public limit_marker_count : number = 5;
  public prod : boolean = true;
  public dataExportDisabled : boolean = false;
}
