import { Component, OnInit } from '@angular/core';
import { DataLogging } from './main';
import { MainService } from './main.service';
import { MainData } from './main-data';
import { DatePipe } from '@angular/common';
import { MainUserData } from './main-user-data';
import {ChartModule} from 'primeng/chart';
import { Group } from "../event-list/event-list-group";
import {Router} from '@angular/router';
import { FilterData } from "../event-list/event-list-filter";
import { Program } from "../event-list/event-list-program";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  providers: [
    DatePipe,
    MainService
  ]
})
export class MainComponent implements OnInit {

  DataLogg: DataLogging[]
  UserDataLogg: MainUserData[]
  DataProp: any[]
  DataVal: any[]
  cols: any[]
  data: MainData[]
  header: any[]
  status_data: any
  all_events: any
  background_translations: any[];
  test_data: any[];
  rowspn: any;
  chartSize: any;
  dataType: any[];
  selectedDataType: any;
  lastProcessDate: any;
  noAssigneData: any = null;
  gaugeHeader: any = "Events status";
  groups: Group[];
  programs: Program[];
  filterdata: FilterData[];
  date_to: any;
  date_from: any;

  constructor(
    private systemCardService: MainService,
    private datePipe: DatePipe,
    private eventListService: MainService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.eventListService.getGroups().then(groups => {
      this.groups = groups;
    });

    this.eventListService.getPrograms().then(programs => {
      this.programs = programs;
    });

    this.selectedDataType = 'all'
    this.systemCardService.getDataLogging(this.selectedDataType).then(DataLogg => {
      this.DataLogg = DataLogg;
      if (this.DataLogg.length > 0)
      {
        this.dataLoggExist();

      }
      else{
        this.dataLoggNotExist();

      }

   });
   if (window.innerWidth < 830){
     this.chartSize = 400 - (830 - window.innerWidth)
   }
   else{
     this.chartSize = 400
   }

   this.systemCardService.getUserDataLogging(this.selectedDataType).then(UserDataLogg => {
     this.UserDataLogg = UserDataLogg;
     if ((this.UserDataLogg[1][Object.getOwnPropertyNames(this.UserDataLogg[1])[0]] == 0) && (this.UserDataLogg[2][Object.getOwnPropertyNames(this.UserDataLogg[2])[0]] == 0) && (this.UserDataLogg[3][Object.getOwnPropertyNames(this.UserDataLogg[3])[0]] == 0)){
       this.noAssigneData = 'no data';
     }
     this.all_events =  (this.UserDataLogg[0][Object.getOwnPropertyNames(this.UserDataLogg[0])[0]])
     this.status_data =  {
       chartType: 'PieChart',
       dataTable: [
        ['Name', 'Value'],
        [ 'New Events',     this.UserDataLogg[1][Object.getOwnPropertyNames(this.UserDataLogg[1])[0]] ],
        [ 'In Progress',     this.UserDataLogg[2][Object.getOwnPropertyNames(this.UserDataLogg[2])[0]] ],
        [ 'Analysed',     this.UserDataLogg[3][Object.getOwnPropertyNames(this.UserDataLogg[3])[0]] ]
      ],
      options: {
      height: this.chartSize, width: this.chartSize ,
      backgroundColor: { fill:'transparent' },
      legend:'bottom',
      colors: ['#EF5350', '#FFEB3B', '#5def6e'],
      chartArea: {  width: "100%", height: "70%" } }
    };
   });

     this.background_translations = [
       { translation: _('app.headers.processing_id_new') },
       { translation: _('app.headers.operations_nr_new') },
       { translation: _('app.headers.flights_nr_new') },
       { translation: _('app.headers.events_nr_new') },
       { translation: _('app.headers.cautions_nr_new') },
       { translation: _('app.headers.warnings_nr_new') },
       { translation: _('app.headers.ua_nr_new') },
       { translation: _('app.headers.cfit_nr_new') },
       { translation: _('app.headers.loc_nr_new') },
       { translation: _('app.headers.eo_nr_new') },
       { translation: _('app.headers.mac_nr_new') },
       { translation: _('app.headers.re_nr_new') },
       { translation: _('app.headers.others_nr_new') },
       { translation: _('app.headers.all_events_new') },
       { translation: _('app.headers.analysed_new') },
       { translation: _('app.headers.ts') },
       { translation: _('app.headers.processing_id_total') },
       { translation: _('app.headers.operations_nr_total') },
       { translation: _('app.headers.flights_nr_total') },
       { translation: _('app.headers.no_event_nr_total') },
       { translation: _('app.headers.events_nr_total') },
       { translation: _('app.headers.cautions_nr_total') },
       { translation: _('app.headers.warnings_nr_total') },
       { translation: _('app.headers.ua_nr_total') },
       { translation: _('app.headers.cfit_nr_total') },
       { translation: _('app.headers.loc_nr_total') },
       { translation: _('app.headers.eo_nr_total') },
       { translation: _('app.headers.mac_nr_total') },
       { translation: _('app.headers.re_nr_total') },
       { translation: _('app.headers.others_nr_total') },
       { translation: _('app.headers.all_events_total') },
       { translation: _('app.headers.analysed_total') }
     ];

    this.dataType = [
      {label: 'Last 7 Days', value: 'last_7_days'},
      {label: 'Last 30 Days', value: 'last_month'},
      {label: 'All', value: 'all'}
    ]
   }


   public dataChange(){
     this.systemCardService.getDataLogging(this.selectedDataType).then(DataLogg => {
       this.DataLogg = DataLogg;
       let nodata = -1
       for (let i of this.DataLogg){
         if ((typeof i['flights_nr']) != 'undefined'){
           if(i['flights_nr'] == 0){
             nodata = 0
           }
           else{
             nodata = 1
           }

         }
       }
       if ((this.DataLogg.length > 0) && (nodata > 0))
       {
         this.dataLoggExist();

       }
       else{
         this.dataLoggNotExist();

       }

    });

    this.systemCardService.getUserDataLogging(this.selectedDataType).then(UserDataLogg => {
      this.UserDataLogg = UserDataLogg;
      if ((this.UserDataLogg[1][Object.getOwnPropertyNames(this.UserDataLogg[1])[0]] == 0) && (this.UserDataLogg[2][Object.getOwnPropertyNames(this.UserDataLogg[2])[0]] == 0) && (this.UserDataLogg[3][Object.getOwnPropertyNames(this.UserDataLogg[3])[0]] == 0)){
        this.noAssigneData = 'no data';
      }
      else{
        this.noAssigneData = null;
      }
      this.all_events =  (this.UserDataLogg[0][Object.getOwnPropertyNames(this.UserDataLogg[0])[0]])
      this.status_data =  {
        chartType: 'PieChart',
        dataTable: [
         ['Name', 'Value'],
         [ 'New Events',     this.UserDataLogg[1][Object.getOwnPropertyNames(this.UserDataLogg[1])[0]] ],
         [ 'In Progress',     this.UserDataLogg[2][Object.getOwnPropertyNames(this.UserDataLogg[2])[0]] ],
         [ 'Analysed',     this.UserDataLogg[3][Object.getOwnPropertyNames(this.UserDataLogg[3])[0]] ]
       ],
       options: {
       height: this.chartSize, width: this.chartSize ,
       backgroundColor: { fill:'transparent' },
       legend:'bottom',
       colors: ['#EF5350', '#FFEB3B', '#5def6e'],
       chartArea: {  width: "100%", height: "70%" } }
     };
    });

   }

   dataLoggExist(){
     this.data = []
     let tempDataTS = Array();
     let tempDataGroup = [0,0,0,0,0,0,0];
     let flightsCount = null
     let oper = null
     //let indexOfOperation = -1;
     this.rowspn = 13;
     for (let i of this.DataLogg){

      if ((typeof i['group_type']) != 'undefined'){
        if (i['group_type'] == 'Unstabilized Approach'){
          tempDataGroup[0] = i['group_count']
        }
        if (i['group_type'] == 'Controlled Flight Into Terrain'){
          tempDataGroup[1] = i['group_count']
        }
        if (i['group_type'] == 'Loss of Control'){
          tempDataGroup[2] = i['group_count']
        }
        if (i['group_type'] == 'Engine Operation'){
          tempDataGroup[3] = i['group_count']
        }
        if (i['group_type'] == 'Other'){
          tempDataGroup[4] = i['group_count']
        }
        if (i['group_type'] == 'Mid-air Collision'){
          tempDataGroup[5] = i['group_count']
        }
        if (i['group_type'] == 'Runway Excursion'){
          tempDataGroup[6] = i['group_count']
        }

       }

       if ((typeof i['ts']) != 'undefined'){
        tempDataTS.push((i['ts']))
       }

       if ((typeof i['operations_nr']) != 'undefined'){
         oper = i
         //console.log('to je to', i)
       }

       if ((typeof i['flights_nr']) != 'undefined'){
         flightsCount = i
         //console.log('to je to', i)
       }
     }

     this.data = [
       {property: 'app.headers.ts', value: (this.datePipe.transform(((Math.max.apply(Math, tempDataTS)) *1000), 'y/MM/dd HH:mm:ss','+0000')) },
       {property: 'app.headers.operations_nr_total', value: oper['operations_nr']},
       {property: 'app.headers.flights_nr_total', value: flightsCount['flights_nr']},
       {property: 'app.headers.no_event_nr_total', value: oper['no_event_nr']},
       {property: 'app.headers.events_nr_total', value: oper['events_nr']},
       {property: 'app.headers.cautions_nr_total', value: oper['cautions_nr']},
       {property: 'app.headers.warnings_nr_total', value: oper['warnings_nr']},
       {property: 'app.headers.ua_nr_total', value: tempDataGroup[0]}, // Unstabilized
       {property: 'app.headers.cfit_nr_total', value: tempDataGroup[1]}, //Controlled flight
       {property: 'app.headers.loc_nr_total', value: tempDataGroup[2]}, //loss of controll
       {property: 'app.headers.eo_nr_total', value: tempDataGroup[3]}, // engine operations
       {property: 'app.headers.mac_nr_total', value: tempDataGroup[5]}, // mid air collision
       {property: 'app.headers.re_nr_total', value: tempDataGroup[6]}, // runaway exur
       {property: 'app.headers.others_nr_total', value: tempDataGroup[4]} //other
     ]
     this.DataLogg = []
     this.DataLogg.push( {} )
   }
   dataLoggNotExist(){
     this.data = [
       {property: 'app.headers.operations_nr_total', value: 0},
       {property: 'app.headers.flights_nr_total', value: 0},
       {property: 'app.headers.no_event_nr_total', value: 0},
       {property: 'app.headers.events_nr_total', value: 0},
       {property: 'app.headers.cautions_nr_total', value: 0},
       {property: 'app.headers.warnings_nr_total', value: 0},
       {property: 'app.headers.ua_nr_total', value: 0},
       {property: 'app.headers.cfit_nr_total', value: 0},
       {property: 'app.headers.loc_nr_total', value: 0},
       {property: 'app.headers.eo_nr_total', value: 0},
       {property: 'app.headers.mac_nr_total', value: 0},
       {property: 'app.headers.re_nr_total', value: 0},
       {property: 'app.headers.others_nr_total', value: 0}
     ]
     if (this.selectedDataType == 'last_7_days'){
       this.data.unshift({property: 'app.headers.ts', value: 'There is no data from last 7 days'})
     }
     if (this.selectedDataType == 'last_month'){
       this.data.unshift({property: 'app.headers.ts', value: 'There is no data from last 30 days'})
     }
     if (this.selectedDataType == 'all'){
       this.data.unshift({property: 'app.headers.ts', value: 'There is no data'})
     }


     this.DataLogg = []
     this.DataLogg.push( {} )
     this.rowspn = 14;
   }

   public columnClick(x){
     this.date_to = null
     this.date_from = null
     var property = x.property.replace("app", "").replace("headers", "").replace("..", "").replace("nr", "").replace("total", "").replace("__", "")

     if (property == 'events'){

       var programIDArray = Array();
       var temporaryIDArray = Array();
       {
         for (let programID in this.programs) {
           for (let taskID in this.programs[programID].children) {
             temporaryIDArray.push(this.programs[programID].children[taskID], this.programs[programID])
             temporaryIDArray[temporaryIDArray.length - 1].partialSelected = false;
           }
         }
         var TempArrDuplicates = Array();
         for (let item of temporaryIDArray) {
           if (TempArrDuplicates.includes(item) == false) {
             TempArrDuplicates.push(item)
           }
         }
       }
       for (let item1 of TempArrDuplicates) {
         if (item1.task_id) {
           programIDArray.push(item1.task_id);
         }
         }

         var groupIDArray = Array();
         var temporaryIDArray = Array();
         {
           for (let groupID in this.groups) {
             for (let subgroupID in this.groups[groupID].children) {
               for (let typeID in this.groups[groupID].children[subgroupID].children) {
                 temporaryIDArray.push(this.groups[groupID].children[subgroupID].children[typeID], this.groups[groupID].children[subgroupID], this.groups[groupID])
                 temporaryIDArray[temporaryIDArray.length - 1].partialSelected = false;
               }
             }
           }
           var tempArrayDuplicates = Array();
           for (let item of temporaryIDArray) {
             if (tempArrayDuplicates.includes(item) == false) {
               tempArrayDuplicates.push(item)
             }
           }
         }

         for (let item1 of tempArrayDuplicates) {
           if (item1.event_type_id) {
             groupIDArray.push(item1.event_type_id);
           }
           }

        if (this.selectedDataType == 'all'){
          this.date_to = null
          this.date_from = null
        }
        if (this.selectedDataType == 'last_month'){
          let x = new Date
          this.date_from = new Date(x.setHours((x.getHours()) -720))
          this.date_to = new Date()
        }
        if (this.selectedDataType == 'last_7_days'){
          let x = new Date
          this.date_from = new Date(x.setHours((x.getHours()) -168))
          this.date_to = new Date()
        }
         this.filterdata = [
           {
             GroupFilter: groupIDArray.join(","),
             DateFilter: this.date_from + "," + this.date_to,
             ProgramFilter: programIDArray.join(","),
             AssignedTo: 'all',
             Status: null,
             Priority: 'all',
             ColumnSelectedFilter: 'all'
           }
         ]
         var output = JSON.stringify(this.filterdata);
         localStorage.setItem('AllFilter', output)
         localStorage.setItem('program_assigne', JSON.stringify(true))
         this.router.navigate(['/event_list']);

     }

     var propertyName = this.shortcutTranslate(x.property.replace("app", "").replace("headers", "").replace("_total", "").replace("..", ""))
     var tempIDArray = Array();
     for (let item of this.groups){
       if (item.value == propertyName){
         {
           for (let groupID in this.groups) {
             for (let subgroupID in this.groups[groupID].children) {
               for (let typeID in this.groups[groupID].children[subgroupID].children) {
                 if (this.groups[groupID].event_group_id == item.event_group_id){
                   tempIDArray.push(this.groups[groupID].children[subgroupID].children[typeID].event_type_id)
                 }
               }
             }
           }
         }
         if (tempIDArray.length == 0){
           tempIDArray = [-1]
         }
         if (this.selectedDataType == 'all'){
           this.date_to = null
           this.date_from = null
         }
         if (this.selectedDataType == 'last_month'){
           let x = new Date
           this.date_from = new Date(x.setHours((x.getHours()) -720))
           this.date_to = new Date()
         }
         if (this.selectedDataType == 'last_7_days'){
           let x = new Date
           this.date_from = new Date(x.setHours((x.getHours()) -168))
           this.date_to = new Date()
         }

         if (localStorage.getItem("AllFilter") === null) {
           this.filterdata = [
             {
               GroupFilter: tempIDArray.join(","),
               DateFilter: this.date_from + "," + this.date_to,
               ProgramFilter: "",
               AssignedTo: 'all',
               Status: null,
               Priority: 'all',
               ColumnSelectedFilter: 'all'
             }
           ]
         }
         else{
           this.filterdata = [
             {
               GroupFilter: tempIDArray.join(","),
               DateFilter: this.date_from + "," + this.date_to,
               ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
               AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
               Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
               Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
               ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
             }
           ]
         }
         var output = JSON.stringify(this.filterdata);
         localStorage.setItem('AllFilter', output)
         localStorage.setItem('program_assigne', JSON.stringify(true))
         this.router.navigate(['/event_list']);

       }
     }
   }

   public shortcutTranslate(shortName){
     if (shortName == 'operations_nr'){ return 'Sessions'}
     if (shortName == 'flights_nr'){ return 'Flights'}
     if (shortName == 'events_nr'){ return 'Events'}
     if (shortName == 'cautions_nr'){ return 'Cautions'}
     if (shortName == 'warnings_nr'){ return 'Warnings'}
     if (shortName == 'ua_nr'){ return 'Unstabilized Approach'}
     if (shortName == 'cfit_nr'){ return 'Controlled Flight Into Terrain'}
     if (shortName == 'loc_nr'){ return 'Loss of Control'}
     if (shortName == 'eo_nr'){ return 'Engine Operation'}
     if (shortName == 'mac_nr'){ return 'Mid-air Collision'}
     if (shortName == 're_nr'){ return 'Runway Excursion'}
     if (shortName == 'others_nr'){ return 'Other'}
   }

   }



  export function _(str: string) {
    return str;
  }
