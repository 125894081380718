import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../conf/config'

@Injectable({
  providedIn: 'root'
})
export class InitValues {
  private airport;
  private groups;
  private programs;

  constructor(
    private http: HttpClient,
    private conf: Config
  ) {

    this.airport= new Promise((resolve, reject) => {
      this.http.get(this.conf.ROOT_API + '/filterbox/airports')
        .subscribe(
          data => {
             resolve(data);
          },
          err => {
            reject();
          }
        );
    })

    this.groups= new Promise((resolve, reject) => {
      this.http.get(this.conf.ROOT_API + '/api/event_group')
        .subscribe(
          data => {
             resolve(data);
          },
          err => {
            reject();
          }
        );
    })

    this.programs= new Promise((resolve, reject) => {
      this.http.get(this.conf.ROOT_API + '/api/programs')
        .subscribe(
          data => {
             resolve(data);
          },
          err => {
            reject();
          }
        );
    })


  }


  getAirports(){
    return this.airport;
  }

  getGroups(){
    return this.groups;
  }

  getPrograms(){
    return this.programs;
  }
}
