import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Filterbox } from '../filterbox.service';


@Component({
  selector: 'filterbox-airports',
  templateUrl: './airport_departure.component.html',
  styleUrls: ['./filterbox.component.css'],
  providers: [ Filterbox ]
})
@Injectable({
  providedIn: 'root'
})
export class AirportDepartureComponent {
  private airportList = [];


  constructor(private filterboxService : Filterbox) {
    filterboxService.getAirports().then((data) => {
      this.airportList = data;
    });
  }


}
