import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs/observable/of';
import { EventListService } from "./event-list.service";
import { Event } from "./event-list";
import { Group } from "./event-list-group";
import { Program } from "./event-list-program";
import { FilterData } from "./event-list-filter"
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HostListener } from "@angular/core";
import { ExcelService } from '../export-excel/export-excel.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css'],
  providers: [
    EventListService,
    DatePipe,
    ExcelService
  ]
})

export class EventListComponent implements OnInit {

  events: Event[];
  groups: Group[];
  programs: Program[];
  selectedPrograms: any[];
  selectedGroups: any[];
  cols: any[];
  smallcols: any[];
  severities: any[];
  selectedColumns: any[];
  multiSortMeta: any[];
  params: any[] = [null];
  date_to: any;
  date_from: any;
  selectedGroupsTmp: any[];
  selectedProgramsTmp: any[];
  desc: any;
  filterdata: FilterData[];
  selectedGroupCount: any;
  selectedProgramCount: any;
  loading: boolean;
  orderResponsive: any[];
  isEfsConnected: any;
  isDataLoaded: boolean = false;
  currentLang: string = 'en';
  langChange: any;
  firstLaunch: any;
  users: any[];
  assigned_to: any = 'all';
  statusList: any[];
  status: any = null;
  importanceList: any[];
  importance: any = 'all';
  expandedRows: any[] = [];
  selectedRows: any[] = [];
  yOffset: any = 0;
  session_id_tooltip: any;
  background_translations: any[];
  firstRun: any = -1;

  constructor(
    private eventListService: EventListService,
    private datePipe: DatePipe,
    private sanit: DomSanitizer,
    private authService: AuthService,
    private translate: TranslateService,
    private exportExcelService: ExcelService,
  ) { }

  public isBolded(s) {
    if (s == true || s == 'new') {
      return this.sanit.bypassSecurityTrustStyle("bold");
    }
  }

  // @HostListener("window:scroll", [])

  ngOnDestroy() {
    localStorage.setItem('pageYoffset', JSON.stringify(window.pageYOffset))
  }

  public expandedRowsChange() {
    localStorage.setItem('expandedRows', JSON.stringify(this.expandedRows))
    localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows))
  }
  public collapseRows() {
    this.expandedRows = []
    this.selectedRows = []
    localStorage.setItem('expandedRows', JSON.stringify(this.expandedRows))
    localStorage.setItem('selectedRows', JSON.stringify(this.selectedRows))
    localStorage.setItem('pageYoffset', JSON.stringify(0))
  }

  ngOnInit() {
    this.firstLaunch = 0;
    this.langChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.session_id_tooltip = this.translate.instant('app.event_list.session_id_tooltip');
      let element = document.getElementById('table');
      let n = document.createTextNode(' ');
      let disp = element.style.display;

      element.appendChild(n);
      element.style.display = 'none';

      setTimeout(function() {
        element.style.display = disp;
        n.parentNode.removeChild(n);
      }, 1);
    });

    this.authService.isEfsConnected().then(data => {
      this.isEfsConnected = data;
      if ((typeof this.translate.currentLang) != 'undefined') {
        this.currentLang = this.translate.currentLang;
      }
      this.isDataLoaded = true;
    });

    this.authService.getUsers().then(data => {
      this.users = data
      this.users.unshift({
        value: null,
        label: _('app.event_list.not_assigned')
      })
      this.users.unshift({
        value: 'all',
        label: _('app.event_list.all_users')
      })
    });

    this.orderResponsive = [["session_id", 525], ["date", 560], ["time_off", 595], ["time_on", 630], ["ac_reg", 665], ["airport_departure", 700], ["airport_arrival", 735], ["ac_type", 770]]
    this.orderResponsive.reverse();

    if (localStorage.getItem("AllFilter") === null) {
      this.filterdata = [
        {
          GroupFilter: "",
          DateFilter: null + "," + null,
          ProgramFilter: "",
          AssignedTo: 'all',
          Status: null,
          Priority: 'all',
          ColumnSelectedFilter: 'all'
        }
      ]
      var output = JSON.stringify(this.filterdata);
      localStorage.setItem('AllFilter', output)
      this.firstRun = 0;
    }
    if (localStorage.getItem('program_assigne') == null) {
      localStorage.setItem('program_assigne', JSON.stringify(true))
    }
    if (localStorage.getItem('expandedRows') !== null) {
      this.expandedRows = JSON.parse(localStorage.getItem('expandedRows'))
    }
    if (localStorage.getItem('selectedRows') !== null) {
      this.selectedRows = JSON.parse(localStorage.getItem('selectedRows'))
    }

    this.params['date_from'] = null;
    this.params['date_to'] = null;
    this.params['selected_group_to_base'] = null;
    this.params['selected_program_to_base'] = null;
    this.params['assigned_to'] = JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo;
    this.params['status'] = JSON.parse(localStorage.getItem('AllFilter'))[0].Status;
    this.params['importance'] = JSON.parse(localStorage.getItem('AllFilter'))[0].Priority;

    this.assigned_to = this.params['assigned_to']
    this.status = this.params['status']
    this.importance = this.params['importance']

    this.severities = [];
    this.desc = "Opis"
    this.multiSortMeta = [];
    this.selectedGroupsTmp = []
    this.selectedProgramsTmp = []
    this.multiSortMeta.push({ field: 'id', order: 1 });
    this.selectedProgramCount = 0
    this.selectedGroupCount = 0



    this.cols = [
      { field: 'session_id', header: _('app.event_list.session id'), search_enabled: false, width_en: '11%', width_pl: '9.5%' },
      { field: 'ac_type', header: _('app.event_list.ac_type'), search_enabled: false, width_en: '10%', width_pl: '10.5%' },
      { field: 'ac_reg', header: _('app.event_list.ac_reg'), search_enabled: false, width_en: '10%', width_pl: '12.5%' },
      { field: 'airport_departure', header: _('app.event_list.airport_departure'), search_enabled: false, width_en: '12%', width_pl: '10%' },
      { field: 'airport_arrival', header: _('app.event_list.airport_arrival'), search_enabled: false, width_en: '10%', width_pl: '12%' },
      { field: 'date', header: _('app.event_list.date'), search_enabled: false, width_en: '8.5%', width_pl: '8.5%' },
      { field: 'time_off', header: _('app.event_list.time_on'), search_enabled: false, width_en: '10%', width_pl: '9%' },
      { field: 'time_on', header: _('app.event_list.time_off'), search_enabled: false, width_en: '10%', width_pl: '10%' }
    ];
    this.smallcols = [
      { field: 'event_id', header: 'id', width: '5%' },
      { field: 'event_group', header: _('app.event_list.event_group'), width: '10%' },
      { field: 'event_subgroup', header: _('app.event_list.event_subgroup'), width: '11%' },
      { field: 'event_type', header: _('app.event_list.event_type'), width: '12%' },
      { field: 'event_start', header: _('app.event_list.event_start'), width: '9%' },
      { field: 'event_end', header: _('app.event_list.event_end'), width: '9%' },
      { field: 'severity', header: _('app.event_list.severity'), width: '9%' },
      { field: 'assigned_to', header: _('app.event_list.assigned_to'), width: '11%' },
      { field: 'status', header: _('app.event_list.status'), width: '7%' },
      { field: 'importance', header: _('app.event_list.importance'), width: '10%' }
    ];
    this.statusList = [
      { value: 'all', label: "----" },
      { value: null, label: _('app.status_list.new') },
      { value: 'discarded', label: _('app.status_list.discarded') },
      { value: 'analysed', label: _('app.status_list.analysed') },
      { value: 'in progress', label: _('app.status_list.in_progress') }
    ];
    this.importanceList = [
      { value: 'all', label: "----" },
      { value: null, label: _('app.importance_list.none') },
      { value: 'low', label: _('app.importance_list.low') },
      { value: 'medium', label: _('app.importance_list.medium') },
      { value: 'high', label: _('app.importance_list.high') }
    ];
    this.background_translations = [
      { translation: _('app.event_list.session_id_tooltip') }
    ]

    this.selectedColumns = []
    var temporaryColumnsList = Array()
    temporaryColumnsList = []
    for (let item of this.orderResponsive) {
      if (window.innerWidth < item[1]) {
        if (temporaryColumnsList.includes(item[0]) == false) {
          temporaryColumnsList.push(item[0])
        }
      }
    }
    for (let item of this.cols) {
      if (temporaryColumnsList.includes(item.field) == false) {
        this.selectedColumns.push(item)
      }

    }

    if (JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter == 'all') {
      this.selectedColumns = []
      for (let item of this.cols) {
        this.selectedColumns.push(item)
      }
    }
    else {
      let selectedColumns = JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      this.selectedColumns = []
      for (let item of this.cols) {
        if (selectedColumns.includes(item.field)) {
          this.selectedColumns.push(item)
        }
      }
    }
    this.eventListService.getGroups().then(groups => {
      this.groups = groups;
      var flag = false;
      while (flag == false) {
        var count = false
        for (let item of this.groups) {
          if (item.children.length == 0) {
            count = true
            let index = this.groups.indexOf(item, 0);
            if (index > -1) {
              this.groups.splice(index, 1);
            }
          }
        }
        if (count == false) {
          flag = true
        }
      }
      this.groups.unshift({ value: 'select_none', label: 'Select none' })
      this.groups.unshift({ value: 'select_all', label: 'Select all' })
      this.GroupFilter();
      this.filterDate();
      if ((this.firstRun < 2) && (this.firstRun > -1)) {
        this.firstRun = this.firstRun + 1;
        this.fetchGroupData();
      }
    });

    this.eventListService.getPrograms().then(programs => {
      this.programs = programs;
      var flag = false;
      while (flag == false) {
        var count = false
        for (let item of this.programs) {
          if (item.children.length == 0) {
            count = true
            let index = this.programs.indexOf(item, 0);
            if (index > -1) {
              this.programs.splice(index, 1);
            }
          }
        }
        if (count == false) {
          flag = true
        }
      }
      this.programs.unshift({ value: 'event_with_program', label: 'Program not defined' })
      this.programs.unshift({ value: 'select_none', label: 'Select none' })
      this.programs.unshift({ value: 'select_all', label: 'Select all' })

      this.ProgramFilter();
      this.filterDate();
      if ((this.firstRun < 2) && (this.firstRun > -1)) {
        this.firstRun = this.firstRun + 1;
        this.fetchProgramData();
      }

    });


  };

  filterDate() {
    if ((new Date()).getTimezoneOffset() < 0) {
        var timezone_mod = '+'
    } else {
        var timezone_mod = '-'
    }
    if (this.date_to) {
      // this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd','+0000')
      // console.log(this.date_to)
      // console.log(this.datePipe.transform(this.date_to, 'y.MM.dd hh:mm:ss zzzz'))
      // console.log((new Date()).getTimezoneOffset())
      // console.log(this.datePipe.transform(this.date_to, 'y.MM.dd hh:mm:ss zzzz', 'UTC'))
      timezone_mod = timezone_mod + Math.abs((new Date()).getTimezoneOffset() / 60 * 100)
      this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd hh:mm:ss zzzz', timezone_mod)
      // console.log(this.datePipe.transform(this.date_to, 'y.MM.dd hh:mm:ss zzzz', timezone_mod))
    }
    else {
      if ((((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1]).includes('undefined')) == false) && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1])) != "null")
        && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1])) != "Invalid Date") && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1])) != "")) {
        this.date_to = new Date(JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1])
        this.params['date_to'] = this.datePipe.transform(JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[1], 'y.MM.dd hh:mm:ss zzzz', timezone_mod);
      }
    }

    if (this.date_from) {
      this.params['date_from'] = this.datePipe.transform(this.date_from, 'y.MM.dd hh:mm:ss zzzz', timezone_mod)
    }
    else {
      if ((((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0]).includes('undefined')) == false) && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0])) != "null")
        && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0])) != "Invalid Date") && (((JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0])) != "")) {
        this.date_from = new Date(JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0])
        this.params['date_from'] = this.datePipe.transform(JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter.split(',')[0], 'y.MM.dd hh:mm:ss zzzz', timezone_mod);
      }
    }
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: this.date_from + "," + this.date_to,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)


    this.firstLaunch++
    if (this.firstLaunch >= 4) {
      this.fetchData();
    }
  }

  clearDateFrom() {
    if ((new Date()).getTimezoneOffset() < 0) {
        var timezone_mod = '+'
    } else {
        var timezone_mod = '-'
    }
    this.date_from = ""
    this.params['date_from'] = this.datePipe.transform(this.date_from, 'y.MM.dd hh:mm:ss zzzz', timezone_mod)

    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: this.date_from + "," + this.date_to,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)

    this.fetchData();
  }

  clearDateTo() {
    if ((new Date()).getTimezoneOffset() < 0) {
        var timezone_mod = '+'
    } else {
        var timezone_mod = '-'
    }
    this.date_to = ""
    this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd hh:mm:ss zzzz', timezone_mod)


    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: this.date_from + "," + this.date_to,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)

    this.fetchData();
  }

  ClearAllEvent() {
    localStorage.setItem('program_assigne', JSON.stringify(true))
    this.selectedPrograms.push(this.programs[2])
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: null + "," + null,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: 'all',
        Status: null,
        Priority: 'all',
        ColumnSelectedFilter: 'all'
      }
    ]
    localStorage.setItem('pageYoffset', JSON.stringify(0))
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)
    this.fetchGroupData();
    this.fetchProgramData();
    localStorage.setItem('program_assigne', JSON.stringify(true))
    this.selectedPrograms.push(this.programs[2])
    this.ProgramFilter();
    this.date_from = ""
    this.date_to = ""
    this.params['date_from'] = null
    this.params['date_to'] = null
    this.status = null
    this.params['status'] = this.status;
    this.importance = 'all'
    this.params['importance'] = this.importance;
    this.assigned_to = 'all'
    this.params['assigned_to'] = this.assigned_to;
    this.fetchData();
    this.selectedColumns = []
    for (let item of this.cols) {
      this.selectedColumns.push(item)
    }

  }

  private fetchData() {
    this.loading = true;
    this.session_id_tooltip = this.translate.instant('app.event_list.session_id_tooltip');
    this.events = [];
    this.eventListService.getEvents(this.params).then(events => {
      this.events = [];

      for (let e of events) {
        if (e.events.length != 0) {
          this.events.push(e)
        }
      }
      for (let item of this.events) {
        let severity = {
          label: item.severity,
          value: item.severity
        }
        let alreadyPushed: boolean = false;
        for (let sev of this.severities) {
          if (severity.value == sev.value) {
            alreadyPushed = true;
          }
        }
        if (alreadyPushed == false) {
          this.severities.push(severity)
        }
        item['date'] = this.datePipe.transform(item.block_off * 1000, 'y.MM.dd','+0000')
        item['time_off'] = this.datePipe.transform(item.block_off * 1000, 'HH:mm:ss','+0000')
        item['time_on'] = this.datePipe.transform(item.block_on * 1000, 'HH:mm:ss','+0000')
        let isThereAnyNewEvent = false;
        for (let i of item.events) {
          if (i['importance'] == null) {
            i['importance'] = '----';
          }
          if (i['status'] == null) {
            i['status'] = 'new';
            isThereAnyNewEvent = true;
          }
          if (i['assigned_to'] == null) {
            i['assigned_to'] = 'unassigned';
          }
          i['event_start'] = this.datePipe.transform(i.event_start * 1000, 'HH:mm:ss','+0000')
          i['event_end'] = this.datePipe.transform(i.event_end * 1000, 'HH:mm:ss','+0000')
        }
        if (isThereAnyNewEvent == true) {
          item['is_bolded'] = true
        }
      };
      this.loading = false;

      setTimeout(function() {

        if (localStorage.getItem('pageYoffset') !== null) {
          window.scrollTo(0, JSON.parse(localStorage.getItem('pageYoffset')))
        }
      }, 1);
    });
  }

  private fetchGroupData() {
    var groupIDArray = Array();
    var temporaryIDArray = Array();
    {
      for (let groupID in this.groups) {
        for (let subgroupID in this.groups[groupID].children) {
          for (let typeID in this.groups[groupID].children[subgroupID].children) {
            temporaryIDArray.push(this.groups[groupID].children[subgroupID].children[typeID], this.groups[groupID].children[subgroupID], this.groups[groupID])
            temporaryIDArray[temporaryIDArray.length - 1].partialSelected = false;
          }
        }
      }
      var tempArrayDuplicates = Array();
      for (let item of temporaryIDArray) {
        if (tempArrayDuplicates.includes(item) == false) {
          tempArrayDuplicates.push(item)
        }
      }
    }
    this.selectedGroups = tempArrayDuplicates

    {
      for (let item1 of this.selectedGroups) {
        if (item1.event_type_id) {
          groupIDArray.push(item1.event_type_id);
        }
      }
      this.filterdata = [
        {
          GroupFilter: groupIDArray.join(","),
          DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
          ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
          AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
          Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
          Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
          ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
        }
      ]
      var output = JSON.stringify(this.filterdata);
      localStorage.setItem('AllFilter', output)
      this.params['selected_group_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter.split(',')
      if (this.params['selected_group_to_base'][0] == ""){
        this.selectedGroupCount = 0
      }
      else{
        this.selectedGroupCount = this.params['selected_group_to_base'].length
      }

      this.selectedGroupsTmp = this.selectedGroups;
    }
  }

  private fetchProgramData() {
    var programIDArray = Array();
    var temporaryIDArray = Array();
    {
      for (let programID in this.programs) {
        for (let taskID in this.programs[programID].children) {
          programIDArray.push(this.programs[programID].children[taskID], this.programs[programID])
          programIDArray[programIDArray.length - 1].partialSelected = false;
        }
      }
      var TempArrDuplicates = Array();
      for (let item of programIDArray) {
        if (TempArrDuplicates.includes(item) == false) {
          TempArrDuplicates.push(item)
        }
      }
      this.selectedPrograms = TempArrDuplicates;
    }

    {
      for (let item1 of this.selectedPrograms) {
        if (item1.task_id) {
          temporaryIDArray.push(item1.task_id);
        }
      }
      this.filterdata = [
        {
          GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
          DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
          ProgramFilter: temporaryIDArray.join(","),
          AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
          Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
          Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
          ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
        }
      ]
      var output = JSON.stringify(this.filterdata);
      localStorage.setItem('AllFilter', output)
      this.params['selected_program_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter.split(',')
      if (this.params['selected_program_to_base'][0] == ""){
        if (localStorage.getItem('program_assigne') == 'true'){
          this.selectedProgramCount = 1
        }
        else{
          this.selectedProgramCount = 0
        }
      }
      else{
        if (localStorage.getItem('program_assigne') == 'true'){
          this.selectedProgramCount = this.params['selected_program_to_base'].length +1
        }
        else{
          this.selectedProgramCount = this.params['selected_program_to_base'].length
        }
      }
      this.selectedProgramsTmp = this.selectedPrograms;
    }
  }

  public ProgramFilter() {
    var SelectAll = false;
    var temporaryIDArray = Array();
    var eventProgramState = false;
    var selectCount;
    var trueState = false;
    if (localStorage.getItem('program_assigne') == 'false') {
      this.params['event_with_program'] = 0
    }
    else {
      this.params['event_with_program'] = 1
    }
    selectCount = 0;
    trueState = false
    for (let item in this.selectedPrograms) {
      if (this.selectedPrograms[item].value == 'select_all') {
        this.params['event_with_program'] = 1
        localStorage.setItem('program_assigne', JSON.stringify(true))
        this.fetchProgramData();
        this.selectedPrograms.push(this.programs[2])
        this.fetchData();
        selectCount = selectCount + 1;
      }
      if (this.selectedPrograms[item].value == 'select_none') {
        this.selectedPrograms = []
        this.params['event_with_program'] = 0
        localStorage.setItem('program_assigne', JSON.stringify(false))
        this.params['selected_program_to_base'] = []
        this.fetchData();
        selectCount = selectCount + 1;
      }

      if (selectCount == 0){
        if (this.selectedPrograms[item].value == 'event_with_program') {
          this.params['event_with_program'] = 1
          localStorage.setItem('program_assigne', JSON.stringify(true))
          trueState = true
        }
        else{
          if (trueState == false){
            this.params['event_with_program'] = 0
            localStorage.setItem('program_assigne', JSON.stringify(false))
          }
        }
      }


    }
    if ((typeof this.selectedPrograms) != 'undefined'){
      if (this.selectedPrograms.length == 0){
        this.params['event_with_program'] = 0
        localStorage.setItem('program_assigne', JSON.stringify(false))
      }
    }

    if (this.selectedPrograms == null) {
      {
        this.params['selected_program_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter.split(',')
        for (let item in this.params['selected_program_to_base']) {
          this.params['selected_program_to_base'][item] = Number(this.params['selected_program_to_base'][item])
          if (this.params['selected_program_to_base'][0] == ""){
            if (localStorage.getItem('program_assigne') == 'true'){
              this.selectedProgramCount = 1
            }
            else{
              this.selectedProgramCount = 0
            }
          }
          else{
            if (localStorage.getItem('program_assigne') == 'true'){
              this.selectedProgramCount = this.params['selected_program_to_base'].length +1
            }
            else{
              this.selectedProgramCount = this.params['selected_program_to_base'].length
            }
          }
        }
        var TempArr = Array();
        {
          for (let programID in this.programs) {
            for (let taskID in this.programs[programID].children) {
              if (this.params['selected_program_to_base'].includes(this.programs[programID].children[taskID].task_id)) {
                TempArr.push(this.programs[programID].children[taskID], this.programs[programID])
              }
            }
          }
          var TempArrDuplicates = Array();
          for (let item of TempArr) {
            if (TempArrDuplicates.includes(item) == false) {
              TempArrDuplicates.push(item)
            }
          }
          // Dla programów
          var ListTest = TempArrDuplicates.map(x => x.task_id).filter(item => typeof item != 'undefined')
          for (let item of this.programs) {
            if ((typeof item.children) != 'undefined') {
              var ListID = item.children.map(x => x.task_id);
              if (arrayContainsAtLeastOne(ListTest, ListID) == true) {
                if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
                }
                else {
                  for (let item1 in TempArrDuplicates) {
                    if (TempArrDuplicates[item1].program_id == item.program_id) {
                      TempArrDuplicates[item1].partialSelected = 'true'
                    }
                  }
                }
              }
            }
          }

          this.selectedPrograms = TempArrDuplicates;
        }
        this.firstLaunch++
      }
      this.selectedProgramsTmp = this.selectedPrograms;
    }
    else if ((this.selectedProgramsTmp != this.selectedPrograms)) {
      for (let item1 of this.selectedPrograms) {
        if (item1.task_id) {
          temporaryIDArray.push(item1.task_id);
        }
      }
      this.filterdata = [
        {
          GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
          DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
          ProgramFilter: temporaryIDArray.join(","),
          AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
          Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
          Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
          ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
        }
      ]
      var output = JSON.stringify(this.filterdata);
      localStorage.setItem('AllFilter', output)
      this.params['selected_program_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter.split(',')
      if (this.params['selected_program_to_base'][0] == ""){
        if (localStorage.getItem('program_assigne') == 'true'){
          this.selectedProgramCount = 1
        }
        else{
          this.selectedProgramCount = 0
        }
      }
      else{
        if (localStorage.getItem('program_assigne') == 'true'){
          this.selectedProgramCount = this.params['selected_program_to_base'].length +1
        }
        else{
          this.selectedProgramCount = this.params['selected_program_to_base'].length
        }
      }
      this.fetchData();
      this.selectedProgramsTmp = this.selectedPrograms;

    }
    if (localStorage.getItem('program_assigne') == 'true') {
      this.selectedPrograms.push(this.programs[2])
    }
    else {
      for (let item in this.selectedPrograms){

      }
    }
    this.selectedPrograms = this.selectedPrograms.filter((el, i, a) => i === a.indexOf(el))
  }

  public GroupFilter() {
    var temporaryIDArray = Array();
    for (let item in this.selectedGroups) {
      if (this.selectedGroups[item].value == 'select_all') {
        this.fetchGroupData();
        this.fetchData();
      }
      if (this.selectedGroups[item].value == 'select_none') {
        this.selectedGroups = []
        this.params['selected_group_to_base'] = []
        this.fetchData();
      }
    }
    if (this.selectedGroups == null) {
      {
        this.params['selected_group_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter.split(',')
        if (this.params['selected_group_to_base'][0] == ""){
          this.params['selected_group_to_base'] = []
        }
        else{
          for (let item in this.params['selected_group_to_base']) {
          this.params['selected_group_to_base'][item] = Number(this.params['selected_group_to_base'][item])
        }

        }
        if (this.params['selected_group_to_base'][0] == ""){
          this.selectedGroupCount = 0
        }
        else{
          this.selectedGroupCount = this.params['selected_group_to_base'].length
        }
        var TempArr = Array();
        {
          for (let groupID in this.groups) {
            for (let subgroupID in this.groups[groupID].children) {
              for (let typeID in this.groups[groupID].children[subgroupID].children) {
                if (this.params['selected_group_to_base'].includes(this.groups[groupID].children[subgroupID].children[typeID].event_type_id)) {
                  TempArr.push(this.groups[groupID].children[subgroupID].children[typeID], this.groups[groupID].children[subgroupID], this.groups[groupID])

                }

              }
            }
          }
          var TempArrDuplicates = Array();
          for (let item of TempArr) {
            if (TempArrDuplicates.includes(item) == false) {
              TempArrDuplicates.push(item)
            }
          }
          // Dla grup
          var ListTest = TempArrDuplicates.map(x => x.event_subgroup_id).filter(item => typeof item != 'undefined')
          for (let item of this.groups) {
            if ((typeof item.children) != 'undefined') {
              var ListID = item.children.map(x => x.event_subgroup_id);
              if (arrayContainsAtLeastOne(ListTest, ListID) == true) {
                if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
                }
                else {
                  for (let item1 in TempArrDuplicates) {
                    if (TempArrDuplicates[item1].event_group_id == item.event_group_id) {
                      TempArrDuplicates[item1].partialSelected = 'true'
                    }
                  }
                }
              }
            }
          }

          // Dla podgrup
          var ListTest = TempArrDuplicates.map(x => x.event_type_id).filter(item => typeof item != 'undefined')
          for (let groupID of this.groups) {
            if ((typeof groupID.children) != 'undefined') {
              for (let subgroupID of groupID.children) {
                var ListID = subgroupID.children.map(x => x.event_type_id);
                if (arrayContainsAtLeastOne(ListTest, ListID) == true) {
                  if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
                  }
                  else {
                    for (let item1 in TempArrDuplicates) {
                      if (TempArrDuplicates[item1].event_subgroup_id == subgroupID.event_subgroup_id) {
                        TempArrDuplicates[item1].partialSelected = 'true'
                        for (let item4 in TempArrDuplicates) {
                          if ((typeof TempArrDuplicates[item4].event_group_id) != 'undefined') {
                            if (TempArrDuplicates[item4].event_group_id == groupID.event_group_id) {
                              TempArrDuplicates[item4].partialSelected = 'true'

                            }
                          }

                        }
                      }
                    }
                  }
                }
              }
            }
          }

          this.selectedGroups = TempArrDuplicates;
        }

        this.firstLaunch++
      }
      this.selectedGroupsTmp = this.selectedGroups;
    }
    else if ((this.selectedGroupsTmp != this.selectedGroups)) {
      for (let item1 of this.selectedGroups) {
        if (item1.event_type_id) {
          temporaryIDArray.push(item1.event_type_id);
        }
      }
      this.filterdata = [
        {
          GroupFilter: temporaryIDArray.join(","),
          DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
          ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
          AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
          Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
          Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
          ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
        }
      ]
      var output = JSON.stringify(this.filterdata);
      localStorage.setItem('AllFilter', output)
      this.params['selected_group_to_base'] = JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter.split(',')
      if (this.params['selected_group_to_base'][0] == ""){
        this.selectedGroupCount = 0
      }
      else{
        this.selectedGroupCount = this.params['selected_group_to_base'].length
      }
      this.fetchData();

      this.selectedGroupsTmp = this.selectedGroups;
    }

  }

  public filterAssignee() {
    this.params['assigned_to'] = this.assigned_to;
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: this.assigned_to,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)
    this.fetchData();
  }

  public filterStatus() {
    this.params['status'] = this.status;
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: this.status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)
    this.fetchData();
  }

  public filterImportance() {
    this.params['importance'] = this.importance;
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: this.importance,
        ColumnSelectedFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ColumnSelectedFilter
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)
    this.fetchData();
  }

  public columnsChange() {
    this.filterdata = [
      {
        GroupFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].GroupFilter,
        DateFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].DateFilter,
        ProgramFilter: JSON.parse(localStorage.getItem('AllFilter'))[0].ProgramFilter,
        AssignedTo: JSON.parse(localStorage.getItem('AllFilter'))[0].AssignedTo,
        Status: JSON.parse(localStorage.getItem('AllFilter'))[0].Status,
        Priority: JSON.parse(localStorage.getItem('AllFilter'))[0].Priority,
        ColumnSelectedFilter: this.selectedColumns.map(x => x.field).join(",")
      }
    ]
    var output = JSON.stringify(this.filterdata);
    localStorage.setItem('AllFilter', output)
  }

  private exportList(){
    let list = ["Date", "Motor Time On", "Motor Time Off", "Event Time Start",
                "Event Time End", "AC Type", "AC Reg", "Departure", "Arrival",
                "Severity", "Type", "Subgroup", "Group"]

    let data = []
    let tmp = {}
    for (let i in this.events){
      tmp = {}
      for (let j in this.events[i].events){
        //time_off and time_on are switched for some reason...
        tmp ={
          Date: this.events[i].date,
          "Motor Time On": this.events[i].time_off,
          "Motor Time Off": this.events[i].time_on,
          "Event Time Start": this.events[i].events[j].event_start,
          "Event Time End": this.events[i].events[j].event_end,
          "AC Type": this.events[i].ac_type,
          "AC Reg": this.events[i].ac_reg,
          "Departure": this.events[i].airport_departure,
          "Arrival": this.events[i].airport_arrival,
          "Severity": this.events[i].events[j].severity,
          "Type": this.events[i].events[j].event_type,
          "Subgroup": this.events[i].events[j].event_subgroup,
          "Group": this.events[i].events[j].event_group
        }
        data.push(tmp);
      }
    }
    this.exportExcelService.exportAsExcelFile(data, 'Event List', list)
  }
}

export function _(str: string) {
  return str;
}

function arrayContainsArray(superset, subset) {
  return subset.every(function(value) {
    return (superset.indexOf(value) >= 0);
  });
}

function arrayContainsAtLeastOne(all, cancontaint) {
  for (let item in cancontaint) {
    if (all.includes(cancontaint[item]) == true) {
      return true
    }
  }
  return false
}
