import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { ngx-translate } from "@biesbjerg/ngx-translate-extract";
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NvD3Module } from 'ng2-nvd3';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CommonModule } from  '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AppRoutingModule } from './app-routing.module';
import { RestService } from './rest/rest.service';
import { MultiSelectModule } from 'primeng/primeng';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ChartModule } from 'primeng/chart';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import 'd3';
import 'nvd3';
import 'canvg';

import { MapExampleComponent } from './initiatives/map-example/map-example.component';
import { ChartExampleComponent } from './initiatives/chart-example/chart-example.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainComponent } from './main/main.component';
import { EventStatsComponent } from './event-stats/event-stats.component';
import { EventListComponent }    from './event-list/event-list.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { AirportDepartureComponent } from './filterbox/components/airport_departure.component'
import { GroupComponent } from './filterbox/components/groups.component'
import { ProgramComponent } from './filterbox/components/programs.component'

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'event_list', component: EventListComponent },
  { path: 'module/map', component: MapExampleComponent },
  { path: 'module/charts', component: ChartExampleComponent },
  { path: 'event_stats', component: EventStatsComponent },
  { path: 'main', component: MainComponent },
  { path: 'event_details/:id', component: EventDetailsComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    MapExampleComponent,
    ChartExampleComponent,
    DashboardComponent,
    MainComponent,
    EventStatsComponent,
    EventListComponent,
    EventStatsComponent,
    EventDetailsComponent,
    AirportDepartureComponent,
    GroupComponent,
    ProgramComponent

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      routes, {useHash: true}
    ),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NvD3Module,
    LeafletModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    BrowserAnimationsModule,
    // DataTableModule,
    // PaginatorModule,
    // DialogModule,
    ButtonModule,
    TableModule,
    MultiSelectModule,
    CheckboxModule,
    TreeModule,
    OverlayPanelModule,
    CalendarModule,
    CommonModule,
    AppRoutingModule,
    Ng2GoogleChartsModule,
    TooltipModule,
    DropdownModule,
    ToastModule,
    ChartModule,
    SelectButtonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
