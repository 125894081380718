import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpParams } from '@angular/common/http'
import { Config } from '../conf/config'

import { Event } from './event-list'
import { Group } from './event-list-group'
import { Program } from './event-list-program'

@Injectable({
  providedIn: 'root'
})

export class EventListService {

  constructor(
    private http: HttpClient,
    private conf: Config
  ) { }

  getEvents(p) {
    let params = new HttpParams().set('date_from', p.date_from)
      .set('date_to', p.date_to)
      .set('events_id', p.selected_group_to_base)
      .set('programs_id',  p.selected_program_to_base)
      .set('assigned_to',  p.assigned_to)
      .set('status',  p.status)
      .set('importance',  p.importance)
      .set('event_with_program', p.event_with_program);
    return this.http.get(this.conf.ROOT_API + '/api/event_list', {params})
      .toPromise()
      .then(res => <Event[]>res);
  }

  getGroups(){
    return this.http.get(this.conf.ROOT_API + '/api/event_group')
      .toPromise()
      .then(res => <Group[]>res);
  }

  getPrograms(){
    return this.http.get(this.conf.ROOT_API + '/api/programs')
      .toPromise()
      .then(res => <Program[]>res);
  }

}
