import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from '../conf/config';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private conf: Config,
  ) { }

  public getToken(): string {
      return localStorage.getItem('token');
  }

  public setToken(token: string){
      localStorage.setItem('token', token);
  }

  public logOut(){
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
  }

  public getUsers(){
    return this.http.get(this.conf.ROOT_API + '/api/users')
      .toPromise()
      .then(res => <any[]>res);
  }

  public isEfsConnected(){
    return this.http.get(this.conf.ROOT_API + '/api/efs_connect')
      .toPromise()
      .then(res => <any>res);
  }

}
