import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Config } from '../conf/config';

import { EventDetails } from './event-details';
import { EventMapData } from './event-map-data';
import { EventBaseInfo } from './event-base-info';


@Injectable({
  providedIn: 'root'
})
export class EventDetailsService {

  constructor(
    private http: HttpClient,
    private conf: Config,
  ) { }

  getEventDetails(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/bars', {params})
      .toPromise()
      .then(res => <EventDetails[]>res);
  }
  getEventDetailsMap(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/map', {params})
      .toPromise()
      .then(res => <EventMapData[]>res);
  }
  getEventDetailsTable(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/table', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  getEventDetailsBaseInfo(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/basic_info', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  getFlightDataToExport(p, p1) {
    let params = new HttpParams().set('event_id', p).set('ac_reg', p1)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/export_flight', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  getColumnName(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/export_file_column_name', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  getEventDataToExport(p, p1) {
    let params = new HttpParams().set('event_id', p).set('ac_reg', p1)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/export_event', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  getColumnTranslate(p) {
    return this.http.get(this.conf.ROOT_API + '/api/event_details/column_name')
      .toPromise()
      .then(res => <any[]>res);
  }
  getEventDetailsChart(p) {
    let params = new HttpParams().set('event_id', p)
    return this.http.get(this.conf.ROOT_API + '/api/event_details/chart', {params})
      .toPromise()
      .then(res => <any[]>res);
  }
  updateEvent(data){
    return this.http.put(this.conf.ROOT_API + '/api/event_details/basic_info', data)
  }
}
