import { Component } from '@angular/core';
import { Dropdown } from 'primeng/primeng';
import { Config } from './conf/config';

let ann = Dropdown['__annotations__']
for (let annotation of ann){
  if (annotation.constructor.name === 'DecoratorFactory') {
    // do not trigger click function on disabled options and set itemClick flag to prevent dropdown closing
    annotation.template = annotation.template.replace('(click)="onItemClick($event, option)"', '(click)="!option.disabled && onItemClick($event, option) || itemClick = true"');
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./styles/app.component.css',
    '../../node_modules/nvd3/build/nv.d3.css',
    '../../node_modules/leaflet/dist/leaflet.css'
  ],
  providers: [
    Config
  ]
})
export class AppComponent {

  constructor(
    private conf: Config,
) { }

  title = 'FDM4GA by Storkjet';


  ngOnInit() {
    if (this.conf.prod) {
      if (location.protocol != 'https:'){
        location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      }
    }
  }

}
