import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Config } from '../conf/config'
import { AuthService } from '../auth/auth.service';
import { DashboardComponent } from '../dashboard/dashboard.component'

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  loading : boolean = false;
  submitted : boolean = false;
  wronglogin : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private conf: Config,
    private auth: AuthService,
    private dash: DashboardComponent,
  ) {}

  private getMap(){
    return {
      login: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value,
    };
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
            this.submitted = true;
            this.loading = true;
            this.wronglogin = false;

            if (this.loginForm.invalid) {
                this.loading = false;
                return;
            }

            this.http.post(this.conf.ROOT_API + '/auth/login', this.getMap())
              .subscribe(
                data => {
                  this.auth.setToken(data['auth_token']);
                  this.dash.token = this.auth.getToken();
                  this.router.navigate(['main']);
                },
                err => {
                  this.loading = false;
                  this.submitted = false;
                  this.wronglogin = true;
                }
              );

        }
}
