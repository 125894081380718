import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
// import { AirportDepartureComponent } from '../filterbox/components/airport_departure.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Group } from "./event-stats";
import { Program } from "../event-list/event-list-program";
import { EventStatsService } from "./event-stats.service";
import { SelectItem } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { DatePipe } from '@angular/common';
import { Filterbox } from '../filterbox/filterbox.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-stats',
  templateUrl: './event-stats.component.html',
  styleUrls: ['./event-stats.component.css'],
  providers: [
    DatePipe
  ]
})
@Injectable({
  providedIn: 'root'
})

export class EventStatsComponent implements OnInit {
  selectedGroups: any[];
  groups: Group[];
  selectedGroupCount: any;
  selectedPrograms: any[];
  programs: Program[];
  selectedProgramCount: any;
  private airportList;
  data: any[];
  dataEventsCount: any[];
  dataFlightTime: any[];
  chartData: any;
  showList: SelectItem[];
  isEfsConnected: any;
  chart_type: any = 'trend_in_time';
  date_from: any = null;
  date_to: any = null;
  student: any = null;
  instructor: any = null;
  ac: any = null;
  apt_dep: any = null;
  apt_arr: any = null;
  severity: any = null;
  time_aggregation: any = 'month';
  params: any[] = [null];
  chart: any;
  loading: boolean = true;
  students: any[];
  instructors: any[];
  acs: any[];
  airports: any[];
  severities: any[];
  timeAggregationList: any[];
  langChange: any;
  background_translations: any[];
  firstRun: any=0;

  constructor(
    private eventStatsService: EventStatsService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private filterboxService : Filterbox,
    private translate: TranslateService
  ) {
      filterboxService.getAirports().then((data) => {
        this.airports = data;
        if(this.airports[0].value!=null){
          this.airports.unshift({
              value: null,
              label: '---'
            })
          }
      });
    }


  ngOnInit() {
    this.students = []
    this.instructors = []
    this.langChange = this.translate.onLangChange.subscribe((event:LangChangeEvent) => {
      this.loading = true;
      this.fetchData();
      setTimeout(function(){
        this.loading = false;
      },3);
    });

    let x = new Date
    this.date_from = new Date(x.setFullYear((x.getFullYear()) -1))
    this.date_to = new Date()

    this.authService.isEfsConnected().then(data => {
      this.isEfsConnected = data;

      this.showList = [
        { value: 'trend_in_time', label: _('app.event_stats.trend_in_time') },
        { value: 'events_per_students', label: _('app.event_stats.events_per_students'), disabled: !this.isEfsConnected },
        { value: 'events_per_instructors', label: _('app.event_stats.events_per_instructors'), disabled: !this.isEfsConnected },
        { value: 'events_per_ac_type', label: _('app.event_stats.events_per_ac_type') },
        { value: 'events_per_ac_reg', label: _('app.event_stats.events_per_ac_reg') },
        { value: 'events_per_program', label: _('app.event_stats.events_per_program'), disabled: !this.isEfsConnected }
      ];
      if (this.isEfsConnected == true){
        this.eventStatsService.getPrograms().then(programs => {
          this.programs = programs;
          var flag = false;
          while (flag == false)
          {
            var count = false
            for (let item of this.programs){
              if (item.children.length == 0){
                count = true
                let index = this.programs.indexOf(item, 0);
                if (index > -1) {
                  this.programs.splice(index, 1);
                }
              }
            }
            if (count == false){
              flag = true
            }
          }
          this.programs.unshift({ value: 'event_with_program', label: 'Events without program'})
          this.programs.unshift({ value: 'select_none', label: 'Select none'})
          this.programs.unshift({value: 'select_all', label: 'Select all'})
          this.fetchProgramData();
          this.firstRun = this.firstRun +1
          if (this.firstRun == 2){
            this.fetchData();
          }
          this.selectedPrograms.push(this.programs[2])
        });

        this.students = [];
        this.eventStatsService.getStudents().then(data => {
          if (data != null){
            this.students = data;
          }
          this.students.unshift({
            value: null,
            label: '---'
          })
        });
        this.instructors = [];
        this.eventStatsService.getInstructors().then(data => {
          if (data != null){
            this.instructors = data;
          }
          this.instructors.unshift({
            value: null,
            label: '---'
          })
        });

      }
      else{
        this.firstRun = this.firstRun +1
        if (this.firstRun == 2){
          this.fetchData();
        }
      }


    });


    this.eventStatsService.getAircrafts().then(data => {
      this.acs = data;
      this.acs.unshift({
        value: null,
        label: '---'
      })
    });

    this.severities = [
      {value: null, label: '---'},
      {value: 'caution', label: 'Caution'},
      {value: 'warning', label: 'Warning'}
    ]

    this.timeAggregationList = [
      {value: null, label: '---', disabled: this.chart_type == 'trend_in_time'},
      {value: 'day', label: _('app.event_stats.day')},
      {value: 'month', label: _('app.event_stats.month')},
      {value: 'year', label: _('app.event_stats.year')}
    ]

    this.params['date_from'] = null;
    this.params['date_to'] = null;
    this.params['student'] = null;
    this.params['instructor'] = null;
    this.params['ac'] = null;
    this.params['apt_dep'] = null;
    this.params['apt_arr'] = null;
    this.params['severity'] = null;
    this.params['selected_group_to_base'] = null;
    this.params['selected_program_to_base'] = null;
    this.params['chart_type'] = this.chart_type;
    this.params['time_aggregation'] = this.time_aggregation;

    this.eventStatsService.getGroups().then(groups => {
      this.groups = groups;
      var flag = false;
      while (flag == false)
      {
        var count = false
        for (let item of this.groups){
          if (item.children.length == 0){
            count = true
            let index = this.groups.indexOf(item, 0);
            if (index > -1) {
              this.groups.splice(index, 1);
            }
          }
        }
        if (count == false){
          flag = true
        }
      }
      this.groups.unshift({value: 'select_none', label: 'Select none'})
      this.groups.unshift({value: 'select_all', label: 'Select all'})
      this.fetchGroupData();
      this.firstRun = this.firstRun +1
      if (this.firstRun == 2){
        this.fetchData();
      }
    });

    this.background_translations = [
      { translation: _('app.event_stats.event_count') },
      { translation: _('app.event_stats.flight_time') }
    ];
  }

  refreshChart(){
    this.fetchData()
  }

  fetchData(){
    this.loading = true;
    this.params['date_from'] = this.datePipe.transform(this.date_from, 'y.MM.dd','+0000');
    this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd','+0000');
    this.params['student'] = this.student;
    this.params['instructor'] = this.instructor;
    this.params['ac'] = this.ac;
    this.params['apt_dep'] = this.apt_dep;
    this.params['apt_arr'] = this.apt_arr;
    this.params['severity'] = this.severity;
    this.params['chart_type'] = this.chart_type;
    this.params['time_aggregation'] = this.time_aggregation;

    this.eventStatsService.getData(this.params).then(data => {
      console.log(data)
      let tmpData1 = Array();
      let tmpData2 = Array();
      for (let x of data){
        if ((typeof x.value) == 'number'){
          tmpData1.push(x)
        }
        else{
          tmpData2.push(x)
        }

      }
      for (let i of tmpData1){
        for (let j of tmpData2){
          if (i.key == j.key){
            i.value2 = j.value2
          }
        }
      }
      this.data = tmpData1;
      this.dataEventsCount = [];
      this.dataFlightTime = [];

      for (let i = 0; i < this.data.length; i++){
        this.dataEventsCount.push({x: i, x_name: this.data[i].key, y: this.data[i].value, y2: this.data[i].value2});
        this.dataFlightTime.push({x: i, y: (this.data[i].value2 ? this.data[i].value2 : 0)});
      }
      var onlyZero = true
      for (let item in this.dataFlightTime){
        if (this.dataFlightTime[item].y == 0){
          onlyZero = true
        }
        else{
          onlyZero = false;
          break;
        }
      }
      for (let i of this.dataEventsCount){
        if (i.x_name == null){
          i.x_name = 'Without Program'
        }
      }
      this.chartData = []
      this.chartData.push({
        values: this.dataEventsCount,
        key: this.translate.instant('app.event_stats.event_count'),
        color: '#4bb1d1',
        bar: true
      })


      this.chartData.push(
      {
        values: this.dataFlightTime,
        key: this.translate.instant('Flight Count'),
        color: '#e88719'
      })

      this.drawChart(this.chartData, this.datePipe, onlyZero)

    });
  }

  drawChart (data, datePipe, linezero){
    let translate = this.translate
    let chart_type = this.chart_type
    let time_aggregation = this.time_aggregation
    nv.addGraph(function () {
    let tVal = [];
    let tNames = [];
    let max = data.map(function(obj) { return obj.values; });
    let maxBar = max[0]
    let maxLine = max[1]
    maxBar = Math.max.apply(Math, maxBar.map(function(obj) { return obj.y; }));
    maxLine = Math.max.apply(Math, maxLine.map(function(obj) { return obj.y; }));
    for (let i = 0; i < data[0].values.length; i++){
      tNames.push(data[0].values[i].x_name)
    }
    var chart = nv.models.linePlusBarChart()

    chart.xAxis
       .ticks(14)
       .showMaxMin(false)
       .rotateLabels(-30)
       .tickFormat(function (d) {
         return tNames[d]
       });

    if(chart_type == 'trend_in_time'){
      chart.xAxis.axisLabel(translate.instant('app.event_stats.'+time_aggregation))
    }else{
      chart.xAxis.axisLabel(translate.instant('app.event_stats.'+chart_type))
    }

    chart.y1Axis
       .axisLabel(translate.instant('app.event_stats.event_count'))
       .tickFormat(d3.format(',0d'))
       .showMaxMin(false)

    chart.y2Axis
       .axisLabel(translate.instant('app.event_stats.flight_time') + "[h]")
       .tickFormat(d3.format(',f'))
       .showMaxMin(false)
       .axisLabelDistance(-15)

    chart
       .focusEnable(false)
       .height(570)
       .margin({"left": 100, "right": 70})

   chart.legend.align(false)
   chart.bars.forceY([0, maxBar*1.1])
   if (linezero == true){
     chart.lines.forceY([0, 1])
     chart.y2Axis.tickValues([0, 1])
   }
   else{
     chart.lines.forceY([0, maxLine*1.1])
   }

    d3.select('#lineChart_stats svg')
       .datum(data)
       .call(chart);

    nv.utils.windowResize(function() { d3.select('#lineChart_stats svg').call(chart); } );

    return chart;
    });
    this.loading = false
  }

  filterDate() {

    if (this.date_to) {
      this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd','+0000')
    }

    if (this.date_from) {
      this.params['date_from'] = this.datePipe.transform(this.date_from, 'y.MM.dd','+0000')
    }

    this.fetchData();
  }

  public GroupClick(){
    var values = Array();
    for (let item in this.selectedGroups){
      if (this.selectedGroups[item].value == 'select_all'){
        this.fetchGroupData();
      }
      if (this.selectedGroups[item].value == 'select_none'){
        this.selectedGroups = []
        this.params['selected_group_to_base'] = []
        this.selectedGroupCount = 0;
        this.fetchData();
      }
    }
    for (let item1 of this.selectedGroups) {
      if (item1.event_type_id) {
        values.push(item1.event_type_id);
      }
    }
    this.params['selected_group_to_base'] = values;
    this.fetchData();
    this.selectedGroupCount = this.params['selected_group_to_base'].length
  }

public ProgramClick(){
  var values = Array();
  var eventProgramState = false;
  this.params['event_with_program'] = 0
  for (let item in this.selectedPrograms){
    if (this.selectedPrograms[item].value == 'select_all') {
      for (let item1 in this.selectedPrograms) {
        if (this.selectedPrograms[item1].value == 'event_with_program'){
          var event_withprogram_state = this.selectedPrograms[item1]
          eventProgramState = true
          break;
        }
      }
      this.fetchProgramData();
      if (eventProgramState){
        this.selectedPrograms.push(event_withprogram_state)
      }
      this.fetchData();

    }

    if (this.selectedPrograms[item].value == 'select_none'){
      for (let item in this.selectedPrograms) {
        if (this.selectedPrograms[item].value == 'event_with_program'){
          let event_withprogram_state = this.selectedPrograms[item]
          this.selectedPrograms = []
          this.selectedPrograms.push(event_withprogram_state)
          eventProgramState = true
          break;
        }
      }
      if (eventProgramState == false){
        this.selectedPrograms = []
      }
      this.params['selected_program_to_base'] = []
      this.fetchData();
    }
    if (this.selectedPrograms[item].value == 'event_with_program'){
      this.params['event_with_program'] = 1
    }
  }
  for (let item1 of this.selectedPrograms) {
    if (item1.task_id) {
      values.push(item1.task_id);
    }
  }
  this.params['selected_program_to_base'] = values;
  this.fetchData();
  this.selectedProgramCount = this.params['selected_program_to_base'].length
}

private fetchGroupData() {
    var arrayID = Array();
    var TempArr = Array();
    {
      for (let group in this.groups) {
        for (let subgroup in this.groups[group].children) {
          for (let event_type in this.groups[group].children[subgroup].children) {
            TempArr.push(this.groups[group].children[subgroup].children[event_type], this.groups[group].children[subgroup], this.groups[group])

          }
        }
      }
      var TempArrDuplicates = Array();
      for (let item of TempArr){
        if (TempArrDuplicates.includes(item) == false){
          TempArrDuplicates.push(item)
        }
      }
    }
    var ListTest = TempArrDuplicates.map(x => x.event_subgroup_id).filter(item => typeof item != 'undefined')
    for (let item of this.groups) {
      if ((typeof item.children) != 'undefined') {
        var ListID = item.children.map(x => x.event_subgroup_id);
        if (arrayContainsAtLeastOne(ListTest, ListID) == true){
          if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
          }
          else{
            for (let item1 in TempArrDuplicates){
              if (TempArrDuplicates[item1].event_group_id == item.event_group_id){
                TempArrDuplicates[item1].partialSelected = 'true'
              }
            }
          }
        }
      }
    }

    // Dla podgrup
    var ListTest = TempArrDuplicates.map(x => x.event_type_id).filter(item => typeof item != 'undefined')
    for (let item of this.groups) {

      if ((typeof item.children) != 'undefined') {
        for (let item2 of item.children){
          var ListID = item2.children.map(x => x.event_type_id);
          if (arrayContainsAtLeastOne(ListTest, ListID) == true){
            if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
            }
            else{
              for (let item1 in TempArrDuplicates){
                if (TempArrDuplicates[item1].event_subgroup_id == item2.event_subgroup_id){
                  TempArrDuplicates[item1].partialSelected = 'true'
                }
              }
            }
          }
        }
      }
    }
    this.selectedGroups = TempArrDuplicates
    for (let item1 of this.selectedGroups) {
      if (item1.event_type_id) {
        arrayID.push(item1.event_type_id);
      }
    }
    this.selectedGroupCount = arrayID.length

}
private fetchProgramData() {
    var TempArr = Array();
    var values = Array();
    {
      for (let item1 in this.programs) {
        for (let item2 in this.programs[item1].children) {
          TempArr.push( this.programs[item1].children[item2], this.programs[item1])
        }
      }
      var TempArrDuplicates = Array();
      for (let item of TempArr){
        if (TempArrDuplicates.includes(item) == false){
          TempArrDuplicates.push(item)
        }
      }
      // Dla programów
      var ListTest = TempArrDuplicates.map(x => x.task_id).filter(item => typeof item != 'undefined')
      for (let item of this.programs) {
        if ((typeof item.children) != 'undefined') {
          var ListID = item.children.map(x => x.task_id);
          if (arrayContainsAtLeastOne(ListTest, ListID) == true){
            if ((arrayContainsArray(ListTest, ListID)) && ListID.length > 0) {
            }
            else{
              for (let item1 in TempArrDuplicates){
                if (TempArrDuplicates[item1].program_id == item.program_id){
                  TempArrDuplicates[item1].partialSelected = 'true'
                }
              }
            }
          }
        }
      }
      this.selectedPrograms = TempArrDuplicates;
      for (let item1 of this.selectedPrograms) {
        if (item1.task_id) {
          values.push(item1.task_id);
        }
      }
      this.selectedProgramCount = values.length
      this.params['selected_program_to_base'] = values
    }
}

clearDateFrom() {
  this.date_from = ""
  this.params['date_from'] = this.datePipe.transform(this.date_from, 'y.MM.dd','+0000')
  this.fetchData();
}

clearDateTo() {
  this.date_to = ""
  this.params['date_to'] = this.datePipe.transform(this.date_to, 'y.MM.dd','+0000')
  this.fetchData();
}


}

export function _(str: string) {
  return str;
}

function arrayContainsArray(superset, subset) {
  return subset.every(function(value) {
    return (superset.indexOf(value) >= 0);
  });
}

function arrayContainsAtLeastOne(all, cancontaint){
  for (let item in cancontaint){
    if (all.includes(cancontaint[item]) == true) {
      return true
    }
  }
  return false
}
