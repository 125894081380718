import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { tileLayer, latLng, Layer, geoJSON, circle } from 'leaflet';
import { EventDetailsService } from './event-details.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import  { Gauge } from './event-details-gauge';
import { EventDetails } from './event-details';
import { EventMapData } from './event-map-data';
import { EventBaseInfo } from './event-base-info';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
import { ExcelService } from '../export-excel/export-excel.service';
declare let d3: any;
import * as L from 'leaflet';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { Config } from '../conf/config';
import * as html2canvas from 'html2canvas';


@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css'],
  providers: [
    DatePipe,
    MessageService,
    DecimalPipe,
    ExcelService,
    Config
  ]
})
export class EventDetailsComponent implements OnInit {

  id: number;
  private sub: any;
  details: EventDetails[];
  mapData: any[];
  tableData: any[];
  colorData: any[];
  cols: any[];
  zoom: number;
  center: object;
  gauge: any;
  layers: Layer[];
  flightData: any[];
  mapListData: any[];
  description: any;
  gauge_width: any;
  tooltip: any;
  baseInfo: any[];
  editableBaseInfo: any[];
  smallcols: any[];
  users: any[];
  statusList: any[];
  importanceList: any[];
  chart_in_time: any;
  isEfsConnected: any;
  lineChart: any[];
  lineChartData : any[];
  lineChartDataWarning : any[];
  lineChartDataCaution : any[];
  lineChartDataWarningSecond : any[];
  lineChartDataCautionSecond : any[];
  background_translations: any[];
  rowspn: any;
  session_id: number;
  abs: boolean;
  eventheadeName: any;
  gaugeHeader: any;
  colorDataCaution: number;
  colorDataWarning: number;
  colorDataCautionMap: number;
  colorDataWarningMap: number;
  mapTableData: any[];
  tableHeaders: any;
  eventStart: any;
  eventStop: any;
  columnTranslate: any[];
  acREG: any;
  eventGroup: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: EventDetailsService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private sanit: DomSanitizer,
    private messageService: MessageService,
    private exportExcelService: ExcelService,
    private conf: Config,
) { }

  public sucMessage() {
        this.messageService.add({severity:'success', summary:_('app.event_details.changes_saved')});
  }

  public badMessage() {
       this.messageService.add({severity:'error', summary: _('app.event_details.wrong_data')});
  }

  public clear() {
        this.messageService.clear();
  }

  public isEditableColumn(p){
    if(p=='status' || p == 'assigned_to' || p == 'importance'){
      return true
    }else{
      return false
    }
  }

  public valuesArray(p){
    if(p == 'assigned_to'){
      return this.users
    }else if(p == 'status'){
      return this.statusList
    }else if(p == 'importance'){
      return this.importanceList
    }
  }

  public save(){

    let save_data = [];
    for(let i of this.editableBaseInfo){
      save_data.push(i)
    }
    for(let i of this.baseInfo){
      save_data.push(i)
    }
    this.service.updateEvent(save_data).subscribe(
      data => {
      this.sucMessage();
    },
    error => {
      this.badMessage();
    });
  }

  public getColorForGeoJson(value, i, l, cordL){
    let color_val : string = "#";
    let r = this.getRedColorForMap(value);
    let g = this.getGreenColorForMap(value);
    let b = this.getBlueColorForMap(value);

    if (cordL == 60){
      if (i<60 || l-i<=60){
        r = 0
        g = 224
      }
    }
    else if (i<(cordL) || (l)-i<=60){
      r = 0
      g = 224
    }

    let r_str : string = r.toString(16);
    if(r < 16){
      r_str = '0' + r_str;
    }

    let g_str : string = g.toString(16);
    if(g < 16){
      g_str = '0' + g_str;
    }

    let b_str : string = b.toString(16);
    if(b < 16){
      b_str = '0' + b_str;
    }

    color_val = color_val + r_str + g_str + b_str;

    return {
        color: color_val,
        weight: 3,
        opacity: 1,
      }
  }

  leafletOptions;
  ngOnInit() {
    this.authService.isEfsConnected().then(data => {
      this.isEfsConnected = data;
      this.isEfsConnected ? this.rowspn = 13 : this.rowspn = 11
    });

    this.authService.getUsers().then(data => {
      this.users = data;
      this.users.unshift({value: null, label: _('app.event_details.unassigned')})
    });

    this.smallcols = [
      { field: 'property', header: _('app.event_details.property') },
      { field: 'value', header: _('app.event_details.value') }
    ];

    this.statusList = [
      { value: null, label: _('app.status_list.new') },
      { value: 'discarded', label: _('app.status_list.discarded') },
      { value: 'analysed', label: _('app.status_list.analysed') },
      { value: 'in progress', label: _('app.status_list.in_progress') }
    ];

    this.importanceList = [
      { value: null, label: "---" },
      { value: 'low', label: _('app.importance_list.low') },
      { value: 'medium', label: _('app.importance_list.medium') },
      { value: 'high', label: _('app.importance_list.high') }
    ];

    this.tooltip = "Description"

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });

    this.service.getEventDetails(this.id).then(details => {
      this.details = details;
      this.GaugeInit();
    });

    this.mapData = [];
    this.cols = [];

    this.service.getEventDetailsChart(this.id).then(data => {
      this.ChartInit(data);
    });

    this.service.getEventDetailsTable(this.id).then(dataTable => {
      this.TableInit(dataTable);
      this.service.getEventDetailsMap(this.id).then(data => {
        this.MapInit(data);
      });
    });

    this.service.getEventDetailsBaseInfo(this.id).then(data => {
      this.BaseInfoInit(data);
    });

    this.leafletOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 17 })
      ],
      zoom: 5,
      center: latLng(0, 0)
    };

    this.background_translations = [
      { translation: _('app.event_details.base_info.status') },
      { translation: _('app.event_details.base_info.importance') },
      { translation: _('app.event_details.base_info.assigned_to') },
      { translation: _('app.event_details.base_info.event_type') },
      { translation: _('app.event_details.base_info.event_end') },
      { translation: _('app.event_details.base_info.student') },
      { translation: _('app.event_details.base_info.instructor') },
      { translation: _('app.event_details.base_info.event_group') },
      { translation: _('app.event_details.base_info.severity') },
      { translation: _('app.event_details.base_info.event_id') },
      { translation: _('app.event_details.base_info.event_subgroup') },
      { translation: _('app.event_details.base_info.event_start') },
      { translation: _('app.event_details.base_info.ac_type') },
      { translation: _('app.event_details.base_info.airport_arrival') },
      { translation: _('app.event_details.base_info.airport_departure') },
      { translation: _('app.event_details.base_info.ac_reg') }
    ];
  }

  ngOnDestroy() {
      this.sub.unsubscribe();
  }

  minmaxGet(array, type){
    var numberarray = Array()
    for(var obj of array){
      if ((typeof obj) === "number"){
        numberarray.push(obj)
      }
    }

    if (type === "maximum"){
      return Math.max.apply(Math, numberarray)
    }
    else{
        return Math.min.apply(Math, numberarray)
    }
  }

  notnullGet(a, b){
    if ((typeof a) === "number"){
      return "Min";
    }
    else{
      return "Max";
    }
  }

  notnullvalueGet(a, b): Number{
    if ((typeof a) === "number"){
      return Number(a);
    }
    else{
      return Number(b);
    }
  }

  limitName(a){
    if (a == 'minimum'){
      return 'Min'
    }
    else{
      return 'Max'
    }
  }

  drawChart(a, arraymargin, dataname){
      {
      nv.addGraph(function() {

      var min = 0
      var max = 0
      if (arraymargin[0] < 0){
        min = arraymargin[0] * 1.1
      }
      else{
        min = arraymargin[0] * 0.9
      }

      if (arraymargin[1] < 0){
        max = arraymargin[1] * 0.9
      }
      else{
        max = arraymargin[1] * 1.1
      }
      var chart = nv.models.lineChart()
          .useInteractiveGuideline(true);

      chart.xAxis
          .axisLabel('Time ')
          .showMaxMin(false)
          .tickFormat(function(d) {
            return d3.time.format('%X')(new Date(d))
        });

      chart.yAxis
         .axisLabel(dataname)
         .tickFormat(d3.format('.02f'))
         .showMaxMin(false)

      chart.forceY([min, max])
     d3.select('#lineChart_in_time svg')
         .datum(a)
         .call(chart);

     nv.utils.windowResize(function() { d3.select('#lineChart_in_time svg').call(chart) });
     return chart;

     });
   }
  }

  ifRPM(value, type){
    if ((type == 'RPM') || (type == 'Altitude of Altimeter')){
      return Math.round(value);
    }
    else{
      return value
    }
  }

  public isColored(column){
    if(column == this.colorData[0]){
      return true;
    }else{
      return false;
    }
  }

  public sanitizeStyle(column, value){
    let r = this.getRedColor(column, value);
    let g = this.getGreenColor(column, value);
    let b = this.getBlueColor(column, value);
    if(column == this.colorData[0]){
      return this.sanit.bypassSecurityTrustStyle("rgba(" + r + ", " + g + ", " + b + ", 0.55)");
    }
  }

  GaugeInit(){
    var redfrom = 0
    var redto = 0
    var yellowfrom = 0
    var yellowto = 0
    var i = 0
    if ((typeof this.details[i]) != 'undefined') {
      if (((typeof this.details[i].min) == 'number') ||  ((typeof this.details[i].max) == 'number')){
        if (this.details[i].limit_type == "minimum"){
          if (this.details[i].caution_limit == null){
            redfrom = Math.min(this.details[i].right_border, this.details[i].left_border);
            redto = this.details[i].warning_limit;
          }
          else if (this.details[i].warning_limit == null){
            yellowfrom = Math.min(this.details[i].right_border, this.details[i].left_border);
            yellowto = this.details[i].caution_limit;
          }
          else{
            yellowfrom = this.details[i].warning_limit;
            yellowto = this.details[i].caution_limit;
            redfrom = Math.min(this.details[i].right_border, this.details[i].left_border);
            redto = this.details[i].warning_limit;
          }

        }
        else if (this.details[i].limit_type == "maximum") {
          if (this.details[i].caution_limit == null){
            redfrom = this.details[i].warning_limit;
            redto = Math.max(this.details[i].right_border, this.details[i].left_border);
          }
          else if (this.details[i].warning_limit == null){
            yellowfrom = this.details[i].caution_limit;
            yellowto = Math.max(this.details[i].right_border, this.details[i].left_border);
          }
          else{
            yellowfrom = this.details[i].caution_limit;
            yellowto = this.details[i].warning_limit;
            redfrom = this.details[i].warning_limit;
            redto = Math.max(this.details[i].right_border, this.details[i].left_border);
          }

        }
      }
      else{
        redfrom = null
        redto = null
        yellowfrom = null
        yellowto = null
      }

        this.description = this.details[i].param_full + " [ " + this.details[i].param_unit + "]"
        if (this.details[i].avg == null){
          this.gauge =  {
            chartType: 'Gauge',
            dataTable: [
              ['name', 'value'],
              ['Value', parseFloat(Number(this.details[i].value).toFixed(2))],
            ],
            options: {
              min: Math.min(this.details[i].left_border, this.details[i].right_border),
              max: Math.max(this.details[i].left_border, this.details[i].right_border),
              width: 220, height: 220,
              redFrom: redfrom, redTo: redto,
              yellowFrom: yellowfrom, yellowTo: yellowto,
              minorTicks: 10
            }
          };

          this.gauge_width = "25%"
        }
        else if (this.details[i].value == null){
          this.gauge =  {
            chartType: 'Gauge',
            dataTable: [
              ['name', 'value'],
              ['AVG', parseFloat(Number(this.ifRPM(this.details[i].avg, this.details[i].param_full)).toFixed(2))],
              [this.notnullGet(this.details[i].min, this.details[i].max), parseFloat(Number(this.notnullvalueGet(this.details[i].min, this.details[i].max)).toFixed(2)) ]
            ],
            options: {
              min: Math.min(this.details[i].left_border, this.details[i].right_border),
              max: Math.max(this.details[i].left_border, this.details[i].right_border),
              width: 440, height: 220,
              redFrom: redfrom, redTo: redto,
              yellowFrom: yellowfrom, yellowTo: yellowto,
              minorTicks: 10
            }
          };
          this.gauge_width = "50%"
        }

    }
  }
  MapInit(data){
    let criticalVal = null;
    let timeWithLimit =  Array();
    if ((this.tableHeaders[0][1]['rec_type'] == 4) || (this.tableHeaders[0][1]['rec_type'] == 5) || (this.tableHeaders[0][1]['rec_type'] == 3) || (this.tableHeaders[0][1]['rec_type'] == 2)) {
      var cordLimit = 60
    }

    else{
      var cordLimit = 240
    }

    if (typeof data[0][0] === 'string'){
        this.mapListData = data.splice(0, 1);
        data = data[0] as any;
    }
    if (((typeof this.mapListData) == 'undefined') && (this.mapTableData.length > 0)) {
      if (this.mapTableData[0][1] > this.mapTableData[0][0]){ // przy wartosci caution wiekszej niż warning wymagana zmiana ostatniego parametru z 2 na 1
        this.mapListData = [[this.mapTableData[0][2], null, null, this.mapTableData[0][1], this.mapTableData[0][0], 1]]
      }
      else{
        this.mapListData = [[this.mapTableData[0][2], null, null, this.mapTableData[0][1], this.mapTableData[0][0], 2]]
      }

      data = data as any;
      for (let i of this.mapTableData){
        timeWithLimit.push(i[3])
      }

      for (var _i = cordLimit; _i < (data.length-cordLimit); _i++) {
        if (_i == cordLimit){
          criticalVal = data[_i].value
        }
        if (this.mapListData[0][0] == "minimum"){
          if (data[_i].value < criticalVal){
            criticalVal = data[_i].value
          }
        }
        else{
          if (data[_i].value > criticalVal){
            criticalVal = data[_i].value
          }
        }
      }

    }
    let critical_coordinates_value = []
    let critical_coordinates = [];

    for (let c of data){
      let temp = {
          type: "Feature",
          properties: {
            id: 1,
            value: c.value
          },
          geometry: {
            type: "LineString",
            coordinates: [
              [c.LNG1, c.LAT1],
              [c.LNG2, c.LAT2]
            ]
          }
      }
      if (typeof this.mapListData != 'undefined'){
        if (this.colorData[8] == 1){
          if ((this.decimalPipe.transform(Math.abs(c.value), '1.0-4') == this.decimalPipe.transform(this.mapListData[0][this.mapListData[0][5]], '1.0-4')) || (this.decimalPipe.transform(c.value, '1.0-4') == criticalVal)) {
            critical_coordinates.push(temp.geometry.coordinates)
            critical_coordinates_value.push(c.value)
          }
        }
        else {
          if ((this.decimalPipe.transform(c.value, '1.0-4') == this.decimalPipe.transform(this.mapListData[0][this.mapListData[0][5]], '1.0-4')) || (this.decimalPipe.transform(c.value, '1.0-4') == criticalVal)) {
            critical_coordinates.push(temp.geometry.coordinates)
            critical_coordinates_value.push(c.value)
          }
        }
      }
      this.mapData.push(temp)
    }
    let samevalue = true
    for(let i = 0; i < critical_coordinates_value.length-1; i++) {
      if (critical_coordinates_value[i] != critical_coordinates_value[i+1]){
        samevalue = false
      }
    }

    if (( samevalue == true ) && ( critical_coordinates.length > this.conf.limit_marker_count )) {
      critical_coordinates = []
    }

    let mapLayers = [];
    if(this.mapListData){
      for (let iter = 0 ; iter < this.mapData.length ; ++iter){
        if (timeWithLimit.length > 0){
          if (timeWithLimit.includes(data[iter].time)) {
            mapLayers[iter] = geoJSON(this.mapData[iter] as any, {style: this.getColorForGeoJson(this.mapData[iter].properties.value, iter, this.mapData.length, cordLimit) } as any);
          }
          else{
            mapLayers[iter] = geoJSON(this.mapData[iter] as any, {style: {weight: 3, opacity: 1} } as any);
          }
        }
        else{
          mapLayers[iter] = geoJSON(this.mapData[iter] as any, {style: this.getColorForGeoJson(this.mapData[iter].properties.value, iter, this.mapData.length, cordLimit) } as any);
        }

      }
    } else {
      for (let iter = 0 ; iter < this.mapData.length ; ++iter){
          mapLayers[iter] = geoJSON(this.mapData[iter] as any, {style: {weight: 3, opacity: 1} } as any);
      }
    }

    this.center = latLng(this.mapData[0].geometry.coordinates[0][1],
                        this.mapData[0].geometry.coordinates[0][0]);
    this.zoom = 13;

    let start_icon = L.icon({
        iconUrl: '../assets/images/start_marker.png',
        // shadowUrl: 'leaf-shadow.png',

        iconSize:     [10, 10], // size of the icon
        // shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [5, 5], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62],  // the same for the shadow
        // popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    let stop_icon = L.icon({
        iconUrl: '../assets/images/stop_marker.png',
        iconSize:     [21, 35],
        iconAnchor:   [15, 34]
    });
    let critical_icon = L.icon({
        iconUrl: '../assets/images/critical_marker.png',
        iconSize:     [20, 26],
        iconAnchor:   [10, 26]
    });
    let start_marker = {
      id: 'marker',
      name: 'Marker',
      enabled: true,
      layer: L.marker([ this.mapData[cordLimit].geometry.coordinates[0][1],
        this.mapData[cordLimit].geometry.coordinates[0][0] ],
        {icon: start_icon})
    };

    let stop_marker = {
      id: 'marker',
      name: 'Marker',
      enabled: true,
      layer: L.marker([ this.mapData[this.mapData.length-(cordLimit+1)].geometry.coordinates[1][1],
        this.mapData[this.mapData.length-(cordLimit+1)].geometry.coordinates[1][0] ],
        {icon: stop_icon})
    };


    this.layers = mapLayers
    this.layers[this.layers.length] = start_marker.layer;
    this.layers[this.layers.length] = stop_marker.layer;

    for(let m of critical_coordinates){
      let critical_marker = {
        id: 'marker',
        name: 'Marker',
        enabled: true,
        layer: L.marker([ m[0][1]-((m[0][1]-m[1][1])/2),
        m[0][0]-((m[0][0]-m[1][0])/2) ], {icon: critical_icon})
      };
      this.layers[this.layers.length] = critical_marker.layer;
    }
  }

  ChartInit(data){
    var tableData = data[1]
    var TimeSecondArr = Array()
    var TimeArr = Array()
    var ValueToArray = Array()
    if (data[0].length <= 2){
      this.lineChart = null
      return
    }
    let headers = tableData.splice(0, 1);
    for(let d of data[0]){
      if (data[0][0].length > 1)
      {
        if (data[0][0][1]['is_second'] == 1){
          if (d[0] == 1){
            TimeSecondArr.push(d[1]*1000)
          }
        }
        else if (data[0][0][1]['is_second'] == 0){
          TimeSecondArr.push(d[1]*1000)
        }
      }
      else{
        TimeSecondArr.push(d[1]*1000)
      }
    }

    for (let item of data[0]){
      if (data[0][0].length > 1)
      {
        if (data[0][0][1]['is_second'] == 1){
          if (item[0] == 1){
            if (data[0][0][1]['is_abs'] == 1){
              ValueToArray.push(Math.abs(item[2]))
            }
            else{
              ValueToArray.push(item[2])
            }
          }
        }

      else{
        if (data[0][0][1]['is_abs'] == 1){
          ValueToArray.push(Math.abs(item[2]))
        }
        else{
          ValueToArray.push(item[2])
        }
      }
    }
    else{
      ValueToArray.push(item[2])
     }
    }
    if (headers[3] == "minimum"){
      var x = Math.min.apply( Math, ValueToArray )
    }
    else{
      var x = Math.max.apply( Math, ValueToArray )
    }
    this.lineChart = []
    this.lineChartData = []
    this.lineChartDataWarning = []
    this.lineChartDataCaution = []
    this.lineChartDataWarningSecond = []
    this.lineChartDataCautionSecond = []
    var temporaryValueToArray = Array();
    for (let item in ValueToArray){
      {
        this.lineChartData.push({x: TimeSecondArr[item], y: ValueToArray[item] })
        if ( data[0][1].length > 2) {

          if ((data[0][1] != null) && (data[0][0][1]['caution_limit'] != null))
          {
            if (data[0][0][1]['is_abs'] == 1){
              this.lineChartDataCaution.push({x: TimeSecondArr[item], y: Math.abs(data[0][0][1]['caution_limit']) })
            }
            else{
              this.lineChartDataCaution.push({x: TimeSecondArr[item], y: data[0][0][1]['caution_limit'] })
            }

          }
        }
        else{
          if ( data[0][1].length > 2)
          {
            if (data[0][0][1]['is_abs'] == 1){
              this.lineChartDataCaution.push({x: TimeSecondArr[item], y: Math.abs(data[0][0][1]['caution_limit']) })
            }
            else{
              this.lineChartDataCaution.push({x: TimeSecondArr[item], y: data[0][0][1]['caution_limit'] })
            }
          }

        }

        if ( data[0][1].length > 2) {
          if ((data[0][1] != null) && (data[0][0][1]['warning_limit'] != null))
          {
            if (data[0][0][1]['is_abs'] == 1){
              this.lineChartDataWarning.push({x: TimeSecondArr[item], y: Math.abs(data[0][0][1]['warning_limit']) })
            }
            else{
              this.lineChartDataWarning.push({x: TimeSecondArr[item], y: data[0][0][1]['warning_limit'] })
            }
          }
        }
        else{
          if ( data[0][1].length > 2)
          {
            if (data[0][0][1]['is_abs'] == 1){
              this.lineChartDataWarning.push({x: TimeSecondArr[item], y: Math.abs(data[0][0][1]['warning_limit']) })
            }
            else{
              this.lineChartDataWarning.push({x: TimeSecondArr[item], y: data[0][0][1]['warning_limit'] })
            }
          }

        }

        for (let item1 in data[1]){
          if ((data[1].length > 0) && (data[0][0][1].event_type_id == data[1][item1][4])){
            if ( (data[1][item1][3])*1000 === TimeSecondArr[item])
            {
              this.lineChartDataWarningSecond.push({x: TimeSecondArr[item1], y: data[1][item1][0] })
              this.lineChartDataCautionSecond.push({x: TimeSecondArr[item1], y: data[1][item1][1] })
            }
          }
        }
      }
    }
    if ((this.lineChartData == []) && (this.lineChartDataCaution == []) && (this.lineChartDataWarning == []) && (this.lineChartDataCautionSecond == []) && (this.lineChartDataWarningSecond == []) ){
    this.lineChart = null
   }
   else{
    var xVals1 = this.lineChartDataCaution.map(function(obj) { return obj.y; });
    var xVals2 = this.lineChartDataWarning.map(function(obj) { return obj.y; });
    var xVals3 = this.lineChartDataCautionSecond.map(function(obj) { return obj.y; });
    var xVals4 = this.lineChartDataWarningSecond.map(function(obj) { return obj.y; });
    xVals3 = xVals3.filter(x => x != null) as number[];
    xVals4 = xVals4.filter(x => x != null) as number[];
    temporaryValueToArray.push(Math.min.apply(Math, [Math.min.apply(Math, ValueToArray), Math.min.apply(Math, xVals1), Math.min.apply(Math, xVals2), Math.min.apply(Math, xVals3), Math.min.apply(Math, xVals4)]))
    temporaryValueToArray.push(Math.max.apply(Math, [Math.max.apply(Math, ValueToArray), Math.max.apply(Math, xVals1), Math.max.apply(Math, xVals2), Math.max.apply(Math, xVals3), Math.max.apply(Math, xVals4)]))

    if (this.lineChartDataWarning.length > 0){
      this.lineChart.push({
        values: this.lineChartDataWarning,
        key: "Warning Limit",
        color: '#ef0222'
      })
    }
    if (this.lineChartDataCaution.length > 0){
      this.lineChart.push({
        values: this.lineChartDataCaution,
        key: 'Caution limit',
        color: "#ef8c02"
      })
    }

    if (this.lineChartData.length > 0){
      this.lineChart.push({
        values: this.lineChartData,
        key: "Observed Value",
        color: '#0764f9'
      })
    }

    if (this.lineChartDataWarningSecond.length > 0){
      this.lineChart.push({
        values: this.lineChartDataWarningSecond,
        key: 'Warning limit ',
        color: "#ef0221"
      })
    }
    if (this.lineChartDataCautionSecond.length > 0){
      this.lineChart.push({
        values: this.lineChartDataCautionSecond,
        key: 'Caution limit ',
        color: "#ef8c01"
      })
    }
    if ( data[0][1].length > 2){
      this.drawChart(this.lineChart, temporaryValueToArray, data[0][0][1]['front_name']);
    }
    else{
      this.lineChart = null
    }

   }
  }
  TableInit(dataTable){
    this.colorData = dataTable[0];
    this.tableData = dataTable[1];
    this.mapTableData = dataTable[2]
    let headers = this.tableData.splice(0, 1);
    this.tableHeaders = headers;
    var columnColored = null
    var calculatedVar = 0
    for (let item in headers[0]){
      if ((headers[0][item]['is_calculated']) != null){
        calculatedVar += 1
      }
      if (Number(item) > 0){
        if (headers[0][item]['name'] == dataTable[0][0]){
          columnColored = item
          this.gaugeHeader = headers[0][item]['full_name'] + " [" + headers[0][item]['param_unit'] + "] "
        }
      }
    }
    if (dataTable[0][8] == 1){
      for (let item in dataTable[1]){
        dataTable[1][item][columnColored] = Math.abs(dataTable[1][item][columnColored])
      }
    }
    if (calculatedVar >= 2){

      for (let item in this.tableData){
        let i = Number(item)
        if ((i%2 == 0) || (i == 0)){
          this.tableData[i][6] = this.tableData[i+1][7]
        }
      }
      for (let item of this.tableData){
        if (item[6] == item[7]){
          let index = this.tableData.indexOf(item, 0);
          if (index > -1) {
            this.tableData.splice(index, 1);
          }
        }
      }
      for (let item in this.tableData){
        this.tableData[item][6] = parseFloat(Number(this.tableData[item][6]).toFixed(2))
        this.tableData[item][7] = parseFloat(Number(this.tableData[item][7]).toFixed(2))
      }
    }
    var TmpValueArr = Array();
    if (dataTable.length > 0){
      if (headers[0][1]['is_second'] == 1){
        for (let item in this.tableData){
          if (this.tableData[item][this.tableData[item].length-2] == 1){
            TmpValueArr.push(this.tableData[item])
          }
        }
        this.tableData = TmpValueArr;
      }
    }



    for (let item in dataTable[1]){
      dataTable[1][item][columnColored] = parseFloat(Number(dataTable[1][item][columnColored]).toFixed(2))
    }
    for(let d of this.tableData){
      d[1] = this.datePipe.transform(d[1]*1000, 'HH:mm:ss')
    }
    //For future - adding Phase column to table
    //if (this.eventGroup == 'Unstabilized Approach'){
    //  headers[0].push({front_name: "Phase", full_name: "Phase ", is_second: 1, name: "phase", param_unit: "", rec_type: 1})
    //  this.tableData = removeEl(this.tableData, this.tableData[0].length-2)
    //}

    let i = 0;
    for (let h of headers[0]){
      if(typeof h != "string"){
        if (h.front_name == 'Time'){
          this.cols.push({
              field: [i], header: h.front_name, tooltip: h.full_name + ' [' + h.param_unit + ']', name: h.name
          });
        }
        //For future - adding Phase column to table
        //else if(h.front_name == 'Phase'){
        //  this.cols.push({field: [i], header: h.front_name , name: h.name})
        //}
        else{
        this.cols.push({
            field: [i], header: h.front_name + " [" + h.param_unit + "]", tooltip: h.full_name + ' [' + h.param_unit + ']', name: h.name
        });
      }
      }

      i++;
    }
  }
  BaseInfoInit(data){
    this.eventGroup = data[1][2]['value']
    this.acREG = data[1][8]['value']
    for(let item of data[1]){
      if (item.property=="event_type"){
        this.eventheadeName = item.value
      }
    }
    this.editableBaseInfo = data[0];
    for (let item of this.editableBaseInfo){
      item["property_translation"] = 'app.event_details.base_info.' + item.property;
    }
    this.baseInfo = data[1];
    for (let item of this.baseInfo){
      if (item.property == 'event_start'){
        this.eventStart = item.value
      }

      if (item.property == 'event_end'){
        this.eventStop = item.value
      }

      if (item.property == 'event_start' || item.property == 'event_end'){
        item.value = this.datePipe.transform(item.value*1000, 'y/MM/dd HH:mm:ss','+0000')
      }
      item["property_translation"] = 'app.event_details.base_info.' + item.property;
    }
    this.session_id = data[2][0].value
  }
  public getRedColor(column, value){
    let color = null;
    if ((this.colorData[4] < 0) && (this.colorData[5] < 0) && (value >= 0)){
      if (this.colorData[1] == 'minimum'){
        return 0;
      }
      else{
        return 255;
      }
    }
    else if ((this.colorData[4] > 0) && (this.colorData[5] > 0) && (value <= 0)){
      if (this.colorData[1] == 'minimum'){
        return 255;
      }
      else{
        return 0;
      }
    }
    if (column == this.colorData[0])  {
      let percentColors = []
       if (this.colorData[1] == 'minimum'){
        percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0x70,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
      } else if (this.colorData[1] == 'maximum'){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      } else {
        //Not yet supported
        return 0xff;
      }
      if((this.colorData[6] == 2 && this.colorData[1] == 'maximum') || (this.colorData[6] == 2 && this.colorData[1] == 'minimum')){
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }


        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if ((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }
        if (value < this.colorDataCaution){
          value = this.colorDataCaution;
          return 0;
        } else if (value > this.colorDataWarning){
          value = this.colorDataWarning;
        }

        let scaleFactor = this.colorDataWarning - this.colorDataCaution;
        value = (value - this.colorDataCaution)/ scaleFactor;
      }else{
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }

        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if ((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }

        if (value > this.colorDataCaution){
          value = this.colorDataCaution;
          return 0;
        } else if (value < this.colorDataWarning){
          value = this.colorDataWarning;
        }
        let scaleFactor = this.colorDataCaution - this.colorDataWarning;
        value = (value - this.colorDataWarning)/ scaleFactor;
      }
      let i = 1;
      for (; i < percentColors.length -1; i++){
        if (value < percentColors[i].pct) {
          break;
        }
      }
      let lower = percentColors[i-1];
      let upper = percentColors[i];
      let range = upper.pct - lower.pct;
      let rangePct = (value - lower.pct) / range;
      let pctLower = 1 - rangePct;
      let pctUpper = rangePct;
      return Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper);
    }else{
      return 0xff;
    }
  }
  public getGreenColor(column, value){
    let color = null;
    if ((this.colorData[4] < 0) && (this.colorData[5] < 0) && (value >= 0)){
      if (this.colorData[1] == 'minimum'){
        return 255;
      }
      else{
        return 0;
      }
    }
    else if ((this.colorData[4] > 0) && (this.colorData[5] > 0) && (value <= 0)){
      if (this.colorData[1] == 'minimum'){
        return 0;
      }
      else{
        return 255;
      }
    }
    if(column == this.colorData[0]){
      let percentColors = []
      if (this.colorData[1] == 'minimum'){
        percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0x70,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
      } else if (this.colorData[1] == 'maximum'){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      } else {
        //Not yet supported
        return 0xff;
      }
      if((this.colorData[6] == 2 && this.colorData[1] == 'maximum') || (this.colorData[6] == 2 && this.colorData[1] == 'minimum')){
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }

        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if ((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }
        if (value < this.colorDataCaution){
          value = this.colorDataCaution;
          return 224;
        } else if (value > this.colorDataWarning){
          value = this.colorDataWarning;
        }
        let scaleFactor = this.colorDataWarning - this.colorDataCaution;
        value = (value - this.colorDataCaution)/ scaleFactor;
      }else{
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }

        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if ((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }
        if (value > this.colorDataCaution){
          value = this.colorDataCaution;
          return 224;
        } else if (value < this.colorDataWarning){
          value = this.colorDataWarning;
        }
        let scaleFactor = this.colorDataCaution - this.colorDataWarning;
        value = (value - this.colorDataWarning)/ scaleFactor;
      }
      let i = 1;
      for (; i < percentColors.length -1; i++){
        if (value < percentColors[i].pct) {
          break;
        }
      }
      let lower = percentColors[i-1];
      let upper = percentColors[i];
      let range = upper.pct - lower.pct;
      let rangePct = (value - lower.pct) / range;
      let pctLower = 1 - rangePct;
      let pctUpper = rangePct;
      return Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper);
    }else{
      return 0xff;
    }
  }
  public getBlueColor(column, value){
    let color = null;
    if ((this.colorData[4] < 0) && (this.colorData[5] < 0) && (value >= 0)){
      return 0;
    }
    else if ((this.colorData[4] > 0) && (this.colorData[5] > 0) && (value <= 0)){
      return 0;
    }
    if(column == this.colorData[0]){
      let percentColors = []
      if (this.colorData[1] == 'minimum'){
        percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0x70,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
      } else if (this.colorData[1] == 'maximum'){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      } else {
        //Not yet supported
        return 0xff;
      }
      if((this.colorData[6] == 2 && this.colorData[1] == 'maximum') || (this.colorData[6] == 2 && this.colorData[1] == 'minimum')){
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }

        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }
        if (value < this.colorDataCaution){
          value = this.colorDataCaution;
        } else if (value > this.colorDataWarning){
          value = this.colorDataWarning;
        }
        let scaleFactor = this.colorDataWarning - this.colorDataCaution;
        value = (value - this.colorDataCaution)/ scaleFactor;
      }else{
        if (value < 0 ){
          this.abs = true;
        }
        else{
          this.abs = false;
        }
        if (this.abs == true){
          value = Math.abs(value)
          this.colorDataCaution = Math.abs(this.colorData[4])
          this.colorDataWarning = Math.abs(this.colorData[5])
          //this.colorData[4] = Math.abs(this.colorData[4])
          //this.colorData[5] = Math.abs(this.colorData[5])
        }
        else{
          this.colorDataCaution = this.colorData[4]
          this.colorDataWarning = this.colorData[5]
        }

        if ((this.colorData[1] == 'maximum') && (this.abs)){
          percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
        }
        else if ((this.colorData[1] == 'minimum') && (this.abs)){
          percentColors = [
            {
              pct: 0.0,
              color: {
                r: 0xe0,
                g: 0xe0,
                b: 0
              }
            },
            {
              pct: 0.5,
              color: {
                r: 0xe0,
                g: 0x70,
                b: 0
              }
            },
            {
              pct: 1.0,
              color: {
                r: 0xe0,
                g: 0x00,
                b: 0
              }
            }
          ];
        }
        if (value > this.colorDataCaution){
          value = this.colorDataCaution;
        } else if (value < this.colorDataWarning){
          value = this.colorDataWarning;
        }
        let scaleFactor = this.colorDataCaution - this.colorDataWarning;
        value = (value - this.colorDataWarning)/ scaleFactor;
      }
      let i = 1;
      for (; i < percentColors.length -1; i++){
        if (value < percentColors[i].pct) {
          break;
        }
      }
      let lower = percentColors[i-1];
      let upper = percentColors[i];
      let range = upper.pct - lower.pct;
      let rangePct = (value - lower.pct) / range;
      let pctLower = 1 - rangePct;
      let pctUpper = rangePct;
      return Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper);
    }else{
      return 0xff;
    }
  }

  public getRedColorForMap(value){
    let color = null;
    let percentColors = []
    if (this.mapListData[0][0] == 'minimum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0xa0,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
    } else if (this.mapListData[0][0] == 'maximum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0xa0,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        }
      ];
    } else {
      //Not yet supported
      return 0xff;
    }
    if((this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'maximum') || (this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'minimum')){
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value < this.mapListData[0][3]){
        value = this.mapListData[0][3];
        return 0;
      } else if (value > this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][4] - this.mapListData[0][3];
      value = (value - this.mapListData[0][3])/ scaleFactor;
    }else{
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value > this.mapListData[0][3]){
        value = this.mapListData[0][3];
        return 0
      } else if (value < this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][3] - this.mapListData[0][4];
      value = (value - this.mapListData[0][4])/ scaleFactor;
    }
    let i = 1;
    for (; i < percentColors.length -1; i++){
      if (value < percentColors[i].pct) {
        break;
      }
    }
    let lower = percentColors[i-1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (value - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    return Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper);
  }
  public getGreenColorForMap(value){
    let color = null;
    let percentColors = []
    if (this.mapListData[0][0] == 'minimum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0xa0,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
    } else if (this.mapListData[0][0] == 'maximum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0xa0,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        }
      ];
    } else {
      //Not yet supported
      return 0xff;
    }

    if((this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'maximum') || (this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'minimum')){
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'maximum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value < this.mapListData[0][3]){
        value = this.mapListData[0][3];
        return 224;
      } else if (value > this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][4] - this.mapListData[0][3];
      value = (value - this.mapListData[0][3])/ scaleFactor;
    }else{
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'maximum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0xa0,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value > this.mapListData[0][3]){
        value = this.mapListData[0][3];
        return 224
      } else if (value < this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][3] - this.mapListData[0][4];
      value = (value - this.mapListData[0][4])/ scaleFactor;
    }
    let i = 1;
    for (; i < percentColors.length -1; i++){
      if (value < percentColors[i].pct) {
        break;
      }
    }
    let lower = percentColors[i-1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (value - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    return Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper);
  }
  public getBlueColorForMap(value){
    //return 0
    let color = null;
    let percentColors = []
    if (this.mapListData[0][0] == 'minimum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0x70,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        }
      ];
    } else if (this.mapListData[0][0] == 'maximum'){
      percentColors = [
        {
          pct: 0.0,
          color: {
            r: 0xe0,
            g: 0xe0,
            b: 0
          }
        },
        {
          pct: 0.5,
          color: {
            r: 0xe0,
            g: 0x70,
            b: 0
          }
        },
        {
          pct: 1.0,
          color: {
            r: 0xe0,
            g: 0x00,
            b: 0
          }
        }
      ];
    } else {
      //Not yet supported
      return 0xff;
    }

    if((this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'maximum') || (this.mapListData[0][5] == 2 && this.mapListData[0][0] == 'minimum')){
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'maximum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value < this.mapListData[0][3]){
        value = this.mapListData[0][3];
      } else if (value > this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][4] - this.mapListData[0][3];
      value = (value - this.mapListData[0][3])/ scaleFactor;
    }else{
      if (value < 0 ){
        this.abs = true;
      }
      else{
        this.abs = false;
      }
      value = Math.abs(value)
      this.mapListData[0][3] = Math.abs(this.mapListData[0][3])
      this.mapListData[0][4] = Math.abs(this.mapListData[0][4])
      if ((this.mapListData[0][0] == 'minimum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          }
        ];
      } else if ((this.mapListData[0][0] == 'maximum') && (this.abs)){
        percentColors = [
          {
            pct: 0.0,
            color: {
              r: 0xe0,
              g: 0xe0,
              b: 0
            }
          },
          {
            pct: 0.5,
            color: {
              r: 0xe0,
              g: 0x70,
              b: 0
            }
          },
          {
            pct: 1.0,
            color: {
              r: 0xe0,
              g: 0x00,
              b: 0
            }
          }
        ];
      }
      if (value > this.mapListData[0][3]){
        value = this.mapListData[0][3];
      } else if (value < this.mapListData[0][4]){
        value = this.mapListData[0][4];
      }

      let scaleFactor = this.mapListData[0][3] - this.mapListData[0][4];
      value = (value - this.mapListData[0][4])/ scaleFactor;
    }
    let i = 1;
    for (; i < percentColors.length -1; i++){
      if (value < percentColors[i].pct) {
        break;
      }
    }
    let lower = percentColors[i-1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (value - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    return Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper);
  }

  public lengthOf (table){
    if((typeof table) != 'undefined')
      return table.length
  }
  saveAsPDF(){
    var css = '@page { size: landscape; }',
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(css));


    head.appendChild(style);

    window.print();
  }

  exportEvent() {
    let removeList = Array();
    let removeFlag = false;
    this.service.getEventDataToExport(this.id, this.acREG).then(data => {
      this.service.getColumnTranslate(this.id).then(data1 => {
        this.columnTranslate = data1;

        for (let i of Object.getOwnPropertyNames(data[1])){
          removeFlag = false;
          for (let j in this.columnTranslate){
            if (this.columnTranslate[j].includes(i)){
              removeFlag = true;
              break;
            }
          }
          if (( removeList.includes(i) == false) && (removeFlag == false)){
            removeList.push(i)
          }
        }

        for(let a of data){

          a['TS'] = this.datePipe.transform(a['TS']*1000, 'y/MM/dd HH:mm:ss','+0000')

          for (let columnData in this.columnTranslate){
            let Tekst = this.columnTranslate[columnData][1]
            a[Tekst] = a[this.columnTranslate[columnData][0]]
            delete a[this.columnTranslate[columnData][0]]
          }
          for (let x of removeList){
            delete a[x]
          }

        }
        let list = new Array(this.columnTranslate.length);
        for (let i in this.columnTranslate){
          list[this.columnTranslate[i][2]-1] = this.columnTranslate[i][1]
        }
        this.exportExcelService.exportAsExcelFile(data, 'Event ' + this.id + ' details', list)
      })

    })
  }

  exportFlight() {
    let removeList = Array();
    let removeFlag = false;
    this.service.getFlightDataToExport(this.id, this.acREG).then(data => {
      this.service.getColumnTranslate(this.id).then(data1 => {
        this.columnTranslate = data1;

        for (let i of Object.getOwnPropertyNames(data[1])){
          removeFlag = false;
          for (let j in this.columnTranslate){
            if (this.columnTranslate[j].includes(i)){
              removeFlag = true;
              break;
            }
          }
          if (( removeList.includes(i) == false) && (removeFlag == false)){
            removeList.push(i)
          }
        }

        for(let a of data){
          if ((a['TS'] <= this.eventStop) && (a['TS'] >= this.eventStart)){
            a['Exceedance'] = 'yes';
          }
          else{
            a['Exceedance'] = 'no'
          }

          a['TS'] = this.datePipe.transform(a['TS']*1000, 'y/MM/dd HH:mm:ss','+0000')

          for (let columnData in this.columnTranslate){
            let Tekst = this.columnTranslate[columnData][1]
            a[Tekst] = a[this.columnTranslate[columnData][0]]
            delete a[this.columnTranslate[columnData][0]]
          }
          for (let x of removeList){
            delete a[x]
          }

        }
        let list = new Array(this.columnTranslate.length+1);
        for (let i in this.columnTranslate){
          list[this.columnTranslate[i][2]-1] = this.columnTranslate[i][1]
        }
        list.unshift('Exceedance')

        this.exportExcelService.exportAsExcelFile(data, 'Flight details with event ' + this.id, list)
      })


    });
  }


}

function removeEl(array, remIdx) {
 return array.map(function(arr) {
         return arr.filter(function(el,idx){return idx !== remIdx});
        });
};

export function _(str: string) {
  return str;
}
