import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpParams } from '@angular/common/http'
import { Config } from '../conf/config'
import { Group } from './event-stats'
import { Program } from "../event-list/event-list-program";

@Injectable({
  providedIn: 'root'
})

export class EventStatsService {

  constructor(
    private http: HttpClient,
    private conf: Config
  ) { }

  getGroups(){
    return this.http.get(this.conf.ROOT_API + '/api/event_group')
      .toPromise()
      .then(res => <Group[]>res);
  }

  getData(p){
    let params = new HttpParams()
      .set('date_from', p.date_from)
      .set('date_to', p.date_to)
      .set('events_id', p.selected_group_to_base)
      .set('programs_id',  p.selected_program_to_base)
      .set('student',  p.student)
      .set('instructor',  p.instructor)
      .set('ac',  p.ac)
      .set('apt_dep',  p.apt_dep)
      .set('apt_arr',  p.apt_arr)
      .set('severity',  p.severity)
      .set('chart_type', p.chart_type)
      .set('time_aggregation', p.time_aggregation)
      .set('event_with_program', p.event_with_program)
    return this.http.get(this.conf.ROOT_API + '/api/event_stats/data', {params})
      .toPromise()
      .then(res => <any[]>res);
  }

  getPrograms(){
    return this.http.get(this.conf.ROOT_API + '/api/programs')
      .toPromise()
      .then(res => <Program[]>res);
  }

  getStudents(){
    return this.http.get(this.conf.ROOT_API + '/api/students')
      .toPromise()
      .then(res => <any[]>res);
  }

  getInstructors(){
    return this.http.get(this.conf.ROOT_API + '/api/instructors')
      .toPromise()
      .then(res => <any[]>res);
  }

  getAirports(){
    return this.http.get(this.conf.ROOT_API + '/api/airports')
      .toPromise()
      .then(res => <any[]>res);
  }

  getAircrafts(){
    return this.http.get(this.conf.ROOT_API + '/api/aircrafts')
      .toPromise()
      .then(res => <any[]>res);
  }

}
