import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpParams } from '@angular/common/http';
import { Config } from '../conf/config';
import { MainUserData } from './main-user-data';
import { DataLogging } from './main';
import { Group } from "../event-list/event-list-group";
import { Program } from "../event-list/event-list-program"

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private http: HttpClient,
    private conf: Config,
  ) { }

  getDataLogging(p) {
    let params = new HttpParams().set('dataType', p)
    return this.http.get(this.conf.ROOT_API + '/api/system_card', {params})
      .toPromise()
      .then(res => <DataLogging[]>res);
  }

  getUserDataLogging(p) {
    let params = new HttpParams().set('dataType', p)
    return this.http.get(this.conf.ROOT_API + '/api/system_card_user_info', {params})
      .toPromise()
      .then(res => <MainUserData[]>res);
  }

  getGroups(){
    return this.http.get(this.conf.ROOT_API + '/api/event_group')
      .toPromise()
      .then(res => <Group[]>res);
  }

  getPrograms(){
    return this.http.get(this.conf.ROOT_API + '/api/programs')
      .toPromise()
      .then(res => <Program[]>res);
  }

}
